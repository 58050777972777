/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { TubSurvey } from '../models/tub-survey';
import { TubListSurveyOrganisations } from '../models/tub-list-survey-organisations';
import { TubSurveyOrganisation } from '../models/tub-survey-organisation';
@Injectable({
  providedIn: 'root',
})
class SurveysService extends __BaseService {
  static readonly SurveysListSurveysPath = '/surveys';
  static readonly SurveysCreateSurveyPath = '/surveys';
  static readonly SurveysGetSurveyPath = '/surveys/{surveyId}/config';
  static readonly SurveysUpdateSurveyPath = '/surveys/{surveyId}/config';
  static readonly SurveysDeleteSurveyPath = '/surveys/{surveyId}';
  static readonly SurveysGetSurveyOrganisationsPath = '/surveys/{surveyId}/organisations';
  static readonly SurveysSetSurveyOrganisationsPath = '/surveys/{surveyId}/organisations';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Paginated list of surveys.
   * @param startAfter ID of survey to start after.
   * @return Ok
   */
  SurveysListSurveysResponse(startAfter?: string): __Observable<__StrictHttpResponse<Array<TubSurvey>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (startAfter != null) __params = __params.set('startAfter', startAfter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/surveys`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TubSurvey>>;
      })
    );
  }
  /**
   * Paginated list of surveys.
   * @param startAfter ID of survey to start after.
   * @return Ok
   */
  SurveysListSurveys(startAfter?: string): __Observable<Array<TubSurvey>> {
    return this.SurveysListSurveysResponse(startAfter).pipe(
      __map(_r => _r.body as Array<TubSurvey>)
    );
  }

  /**
   * Create a new survey.
   * @param config Configuration of the new survey.
   * @return Ok
   */
  SurveysCreateSurveyResponse(config: TubSurvey): __Observable<__StrictHttpResponse<TubSurvey>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = config;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/surveys`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubSurvey>;
      })
    );
  }
  /**
   * Create a new survey.
   * @param config Configuration of the new survey.
   * @return Ok
   */
  SurveysCreateSurvey(config: TubSurvey): __Observable<TubSurvey> {
    return this.SurveysCreateSurveyResponse(config).pipe(
      __map(_r => _r.body as TubSurvey)
    );
  }

  /**
   * Get the survey with the given ID.
   * @param surveyId ID of the survey to get.
   * @return Ok
   */
  SurveysGetSurveyResponse(surveyId: string): __Observable<__StrictHttpResponse<TubSurvey>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/surveys/${encodeURIComponent(String(surveyId))}/config`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubSurvey>;
      })
    );
  }
  /**
   * Get the survey with the given ID.
   * @param surveyId ID of the survey to get.
   * @return Ok
   */
  SurveysGetSurvey(surveyId: string): __Observable<TubSurvey> {
    return this.SurveysGetSurveyResponse(surveyId).pipe(
      __map(_r => _r.body as TubSurvey)
    );
  }

  /**
   * Replaces the survey with the ID with the new configuration.
   * @param params The `SurveysService.SurveysUpdateSurveyParams` containing the following parameters:
   *
   * - `surveyId`: ID of the survey to replace.
   *
   * - `config`: The new survey configuration options.
   *
   * - `ETag`: Last modified etag object.
   *
   * @return Ok
   */
  SurveysUpdateSurveyResponse(params: SurveysService.SurveysUpdateSurveyParams): __Observable<__StrictHttpResponse<TubSurvey>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.config;
    if (params.ETag != null) __headers = __headers.set('ETag', params.ETag.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/surveys/${encodeURIComponent(String(params.surveyId))}/config`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubSurvey>;
      })
    );
  }
  /**
   * Replaces the survey with the ID with the new configuration.
   * @param params The `SurveysService.SurveysUpdateSurveyParams` containing the following parameters:
   *
   * - `surveyId`: ID of the survey to replace.
   *
   * - `config`: The new survey configuration options.
   *
   * - `ETag`: Last modified etag object.
   *
   * @return Ok
   */
  SurveysUpdateSurvey(params: SurveysService.SurveysUpdateSurveyParams): __Observable<TubSurvey> {
    return this.SurveysUpdateSurveyResponse(params).pipe(
      __map(_r => _r.body as TubSurvey)
    );
  }

  /**
   * Delete a survey.
   * @param params The `SurveysService.SurveysDeleteSurveyParams` containing the following parameters:
   *
   * - `surveyId`: ID of survey to delete.
   *
   * - `ETag`: last modified etag.
   */
  SurveysDeleteSurveyResponse(params: SurveysService.SurveysDeleteSurveyParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.ETag != null) __headers = __headers.set('ETag', params.ETag.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/surveys/${encodeURIComponent(String(params.surveyId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete a survey.
   * @param params The `SurveysService.SurveysDeleteSurveyParams` containing the following parameters:
   *
   * - `surveyId`: ID of survey to delete.
   *
   * - `ETag`: last modified etag.
   */
  SurveysDeleteSurvey(params: SurveysService.SurveysDeleteSurveyParams): __Observable<null> {
    return this.SurveysDeleteSurveyResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Paginated get of survey organisations. Can get SELECTED organisations that will be included in the survey,
   * or AVAILABLE organisations that are available to be added to the survey.
   * @param params The `SurveysService.SurveysGetSurveyOrganisationsParams` containing the following parameters:
   *
   * - `type`: 'selected' or 'available'.
   *
   * - `surveyId`: ID of the survey to query. Used to determine selected/available organisations.
   *
   * - `size`: Optional size of results.
   *
   * - `search`: Optional search term to filter.
   *
   * - `from`: Optional from index for pagination.
   *
   * @return Ok
   */
  SurveysGetSurveyOrganisationsResponse(params: SurveysService.SurveysGetSurveyOrganisationsParams): __Observable<__StrictHttpResponse<TubListSurveyOrganisations>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.type != null) __params = __params.set('type', params.type.toString());

    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.from != null) __params = __params.set('from', params.from.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/surveys/${encodeURIComponent(String(params.surveyId))}/organisations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubListSurveyOrganisations>;
      })
    );
  }
  /**
   * Paginated get of survey organisations. Can get SELECTED organisations that will be included in the survey,
   * or AVAILABLE organisations that are available to be added to the survey.
   * @param params The `SurveysService.SurveysGetSurveyOrganisationsParams` containing the following parameters:
   *
   * - `type`: 'selected' or 'available'.
   *
   * - `surveyId`: ID of the survey to query. Used to determine selected/available organisations.
   *
   * - `size`: Optional size of results.
   *
   * - `search`: Optional search term to filter.
   *
   * - `from`: Optional from index for pagination.
   *
   * @return Ok
   */
  SurveysGetSurveyOrganisations(params: SurveysService.SurveysGetSurveyOrganisationsParams): __Observable<TubListSurveyOrganisations> {
    return this.SurveysGetSurveyOrganisationsResponse(params).pipe(
      __map(_r => _r.body as TubListSurveyOrganisations)
    );
  }

  /**
   * Set survey organisations.
   * @param params The `SurveysService.SurveysSetSurveyOrganisationsParams` containing the following parameters:
   *
   * - `type`: 'selected' or 'available'.
   *
   * - `surveyId`: ID of survey to update.
   *
   * - `organisations`: Array of organisations to add or remove.
   */
  SurveysSetSurveyOrganisationsResponse(params: SurveysService.SurveysSetSurveyOrganisationsParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.type != null) __params = __params.set('type', params.type.toString());

    __body = params.organisations;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/surveys/${encodeURIComponent(String(params.surveyId))}/organisations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Set survey organisations.
   * @param params The `SurveysService.SurveysSetSurveyOrganisationsParams` containing the following parameters:
   *
   * - `type`: 'selected' or 'available'.
   *
   * - `surveyId`: ID of survey to update.
   *
   * - `organisations`: Array of organisations to add or remove.
   */
  SurveysSetSurveyOrganisations(params: SurveysService.SurveysSetSurveyOrganisationsParams): __Observable<null> {
    return this.SurveysSetSurveyOrganisationsResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module SurveysService {

  /**
   * Parameters for SurveysUpdateSurvey
   */
  export interface SurveysUpdateSurveyParams {

    /**
     * ID of the survey to replace.
     */
    surveyId: string;

    /**
     * The new survey configuration options.
     */
    config: TubSurvey;

    /**
     * Last modified etag object.
     */
    ETag: string;
  }

  /**
   * Parameters for SurveysDeleteSurvey
   */
  export interface SurveysDeleteSurveyParams {

    /**
     * ID of survey to delete.
     */
    surveyId: string;

    /**
     * last modified etag.
     */
    ETag: string;
  }

  /**
   * Parameters for SurveysGetSurveyOrganisations
   */
  export interface SurveysGetSurveyOrganisationsParams {

    /**
     * 'selected' or 'available'.
     */
    type: string;

    /**
     * ID of the survey to query. Used to determine selected/available organisations.
     */
    surveyId: string;

    /**
     * Optional size of results.
     */
    size?: number;

    /**
     * Optional search term to filter.
     */
    search?: string;

    /**
     * Optional from index for pagination.
     */
    from?: number;
  }

  /**
   * Parameters for SurveysSetSurveyOrganisations
   */
  export interface SurveysSetSurveyOrganisationsParams {

    /**
     * 'selected' or 'available'.
     */
    type: string;

    /**
     * ID of survey to update.
     */
    surveyId: string;

    /**
     * Array of organisations to add or remove.
     */
    organisations: Array<TubSurveyOrganisation>;
  }
}

export { SurveysService }
