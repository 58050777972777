/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { TubReportOptions } from '../models/tub-report-options';
import { TubListReports } from '../models/tub-list-reports';
import { TubMiReport } from '../models/tub-mi-report';
import { TubMomentSignedUrl } from '../models/tub-moment-signed-url';
import { TubReportSchedule } from '../models/tub-report-schedule';
@Injectable({
  providedIn: 'root',
})
class ReportingToolService extends __BaseService {
  static readonly ReportingRequestReportPath = '/reporting';
  static readonly ReportingListReportsPath = '/reporting';
  static readonly ReportingRenameReportPath = '/reporting/{reportId}';
  static readonly ReportingGetReportPath = '/reporting/{reportId}';
  static readonly ReportingRetryFailedReportPath = '/reporting/action/{reportId}/retry';
  static readonly ReportingGetReportPolicyDocumentPath = '/reporting/action/zip';
  static readonly ReportingCheckCodesForValidityPath = '/reporting/action/validate';
  static readonly ReportingUpdateReportSchedulerPath = '/reporting/report-scheduler/update';
  static readonly ReportingGetReportSchedulerPath = '/reporting/report-scheduler/get';
  static readonly ReportingDownloadReportSchedulerCSVPath = '/reporting/report-scheduler/download:reportSchedulerCsv';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Request a new set of reports be generated.
   *
   * This operation is asynchronous - a report will *not* be generated once the endpoint returns.
   * @param options The report options(s).
   */
  ReportingRequestReportResponse(options: Array<TubReportOptions>): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = options;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/reporting`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Request a new set of reports be generated.
   *
   * This operation is asynchronous - a report will *not* be generated once the endpoint returns.
   * @param options The report options(s).
   */
  ReportingRequestReport(options: Array<TubReportOptions>): __Observable<null> {
    return this.ReportingRequestReportResponse(options).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Paginated list of reports, which the requestee is the author of.
   * @param params The `ReportingToolService.ReportingListReportsParams` containing the following parameters:
   *
   * - `size`: Maximum number of reports to return.
   *
   * - `search`: Optional search term to filter to.
   *
   * - `from`: Elasticsearch index from which to start.
   *
   * @return Indicates that the request was successful.
   */
  ReportingListReportsResponse(params: ReportingToolService.ReportingListReportsParams): __Observable<__StrictHttpResponse<TubListReports>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.from != null) __params = __params.set('from', params.from.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/reporting`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubListReports>;
      })
    );
  }
  /**
   * Paginated list of reports, which the requestee is the author of.
   * @param params The `ReportingToolService.ReportingListReportsParams` containing the following parameters:
   *
   * - `size`: Maximum number of reports to return.
   *
   * - `search`: Optional search term to filter to.
   *
   * - `from`: Elasticsearch index from which to start.
   *
   * @return Indicates that the request was successful.
   */
  ReportingListReports(params: ReportingToolService.ReportingListReportsParams): __Observable<TubListReports> {
    return this.ReportingListReportsResponse(params).pipe(
      __map(_r => _r.body as TubListReports)
    );
  }

  /**
   * Request a report be renamed.
   *
   * Request a report be renamed. Provided the same title as the current version will result in no operations executing.
   * If a report is renamed it will invoke the report watcher to rebuild the report PDF. This operation is asynchronous,
   * therefore the report PDF will not be regenerated when the endpoint completes.
   * @param params The `ReportingToolService.ReportingRenameReportParams` containing the following parameters:
   *
   * - `reportId`: The firestore document ID of the report to rename.
   *
   * - `json`: The json object containing the new report name.
   *
   * - `ETag`: Firebase document etag. Should be retrieved from {
   */
  ReportingRenameReportResponse(params: ReportingToolService.ReportingRenameReportParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.json;
    if (params.ETag != null) __headers = __headers.set('ETag', params.ETag.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/reporting/${encodeURIComponent(String(params.reportId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Request a report be renamed.
   *
   * Request a report be renamed. Provided the same title as the current version will result in no operations executing.
   * If a report is renamed it will invoke the report watcher to rebuild the report PDF. This operation is asynchronous,
   * therefore the report PDF will not be regenerated when the endpoint completes.
   * @param params The `ReportingToolService.ReportingRenameReportParams` containing the following parameters:
   *
   * - `reportId`: The firestore document ID of the report to rename.
   *
   * - `json`: The json object containing the new report name.
   *
   * - `ETag`: Firebase document etag. Should be retrieved from {
   */
  ReportingRenameReport(params: ReportingToolService.ReportingRenameReportParams): __Observable<null> {
    return this.ReportingRenameReportResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Return the report with the given ID, only if the requestee is the author.
   * @param reportId The firestore document ID of the report.
   * @return Indicates that the request was successful.
   */
  ReportingGetReportResponse(reportId: string): __Observable<__StrictHttpResponse<TubMiReport>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/reporting/${encodeURIComponent(String(reportId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubMiReport>;
      })
    );
  }
  /**
   * Return the report with the given ID, only if the requestee is the author.
   * @param reportId The firestore document ID of the report.
   * @return Indicates that the request was successful.
   */
  ReportingGetReport(reportId: string): __Observable<TubMiReport> {
    return this.ReportingGetReportResponse(reportId).pipe(
      __map(_r => _r.body as TubMiReport)
    );
  }

  /**
   * Retry generating a failed report.
   *
   * Reset the failure status of a report, and invoke the report watcher to restart generation wherever the report last stopped.
   * @param params The `ReportingToolService.ReportingRetryFailedReportParams` containing the following parameters:
   *
   * - `reportId`: The firestore document ID of the report to retry.
   *
   * - `ETag`: Firebase document etag. Should be retrieved from {
   */
  ReportingRetryFailedReportResponse(params: ReportingToolService.ReportingRetryFailedReportParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.ETag != null) __headers = __headers.set('ETag', params.ETag.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/reporting/action/${encodeURIComponent(String(params.reportId))}/retry`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Retry generating a failed report.
   *
   * Reset the failure status of a report, and invoke the report watcher to restart generation wherever the report last stopped.
   * @param params The `ReportingToolService.ReportingRetryFailedReportParams` containing the following parameters:
   *
   * - `reportId`: The firestore document ID of the report to retry.
   *
   * - `ETag`: Firebase document etag. Should be retrieved from {
   */
  ReportingRetryFailedReport(params: ReportingToolService.ReportingRetryFailedReportParams): __Observable<null> {
    return this.ReportingRetryFailedReportResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Request a collection of reports to be zipped into a single file to download.
   * @param json JSON object containing the array of report IDs to download.
   * @return Indicates that the request was successful.
   */
  ReportingGetReportPolicyDocumentResponse(json: {reportIds?: Array<string>}): __Observable<__StrictHttpResponse<TubMomentSignedUrl>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = json;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/reporting/action/zip`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubMomentSignedUrl>;
      })
    );
  }
  /**
   * Request a collection of reports to be zipped into a single file to download.
   * @param json JSON object containing the array of report IDs to download.
   * @return Indicates that the request was successful.
   */
  ReportingGetReportPolicyDocument(json: {reportIds?: Array<string>}): __Observable<TubMomentSignedUrl> {
    return this.ReportingGetReportPolicyDocumentResponse(json).pipe(
      __map(_r => _r.body as TubMomentSignedUrl)
    );
  }

  /**
   * Verifying that the given access codes are valid for the requestee.
   *
   * Access codes which do not exist, or for which the requester does not have access to, will be returned.
   * @param json JSON object containing the array of access codes to validate.
   * @return Indicates that the request was successful.
   */
  ReportingCheckCodesForValidityResponse(json: {accessCodes?: Array<string>}): __Observable<__StrictHttpResponse<Array<string>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = json;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/reporting/action/validate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<string>>;
      })
    );
  }
  /**
   * Verifying that the given access codes are valid for the requestee.
   *
   * Access codes which do not exist, or for which the requester does not have access to, will be returned.
   * @param json JSON object containing the array of access codes to validate.
   * @return Indicates that the request was successful.
   */
  ReportingCheckCodesForValidity(json: {accessCodes?: Array<string>}): __Observable<Array<string>> {
    return this.ReportingCheckCodesForValidityResponse(json).pipe(
      __map(_r => _r.body as Array<string>)
    );
  }

  /**
   * Takes a TubReportSchedule and saves it to firestore
   * @param reportList
   * @return Indicates that the request was successful.
   */
  ReportingUpdateReportSchedulerResponse(reportList: TubReportSchedule): __Observable<__StrictHttpResponse<Array<string>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = reportList;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/reporting/report-scheduler/update`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<string>>;
      })
    );
  }
  /**
   * Takes a TubReportSchedule and saves it to firestore
   * @param reportList
   * @return Indicates that the request was successful.
   */
  ReportingUpdateReportScheduler(reportList: TubReportSchedule): __Observable<Array<string>> {
    return this.ReportingUpdateReportSchedulerResponse(reportList).pipe(
      __map(_r => _r.body as Array<string>)
    );
  }

  /**
   * Returns TubReportSchedule as a all the listed report to be generated automatically
   * @return Ok
   */
  ReportingGetReportSchedulerResponse(): __Observable<__StrictHttpResponse<TubReportSchedule>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/reporting/report-scheduler/get`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubReportSchedule>;
      })
    );
  }
  /**
   * Returns TubReportSchedule as a all the listed report to be generated automatically
   * @return Ok
   */
  ReportingGetReportScheduler(): __Observable<TubReportSchedule> {
    return this.ReportingGetReportSchedulerResponse().pipe(
      __map(_r => _r.body as TubReportSchedule)
    );
  }

  /**
   * @return Ok
   */
  ReportingDownloadReportSchedulerCSVResponse(): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/reporting/report-scheduler/download:reportSchedulerCsv`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @return Ok
   */
  ReportingDownloadReportSchedulerCSV(): __Observable<string> {
    return this.ReportingDownloadReportSchedulerCSVResponse().pipe(
      __map(_r => _r.body as string)
    );
  }
}

module ReportingToolService {

  /**
   * Parameters for ReportingListReports
   */
  export interface ReportingListReportsParams {

    /**
     * Maximum number of reports to return.
     */
    size?: number;

    /**
     * Optional search term to filter to.
     */
    search?: string;

    /**
     * Elasticsearch index from which to start.
     */
    from?: number;
  }

  /**
   * Parameters for ReportingRenameReport
   */
  export interface ReportingRenameReportParams {

    /**
     * The firestore document ID of the report to rename.
     */
    reportId: string;

    /**
     * The json object containing the new report name.
     */
    json: {title?: string};

    /**
     * Firebase document etag. Should be retrieved from {
     */
    ETag: string;
  }

  /**
   * Parameters for ReportingRetryFailedReport
   */
  export interface ReportingRetryFailedReportParams {

    /**
     * The firestore document ID of the report to retry.
     */
    reportId: string;

    /**
     * Firebase document etag. Should be retrieved from {
     */
    ETag: string;
  }
}

export { ReportingToolService }
