/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { TubMfaChallengeResponseTokenResponse } from '../models/tub-mfa-challenge-response-token-response';
@Injectable({
  providedIn: 'root',
})
class EmailCodesMultiFactorAuthenticationService extends __BaseService {
  static readonly EmailMfaGetChallengePath = '/iams/mfa/email/challenge';
  static readonly EmailMfaPostChallengeResponsePath = '/iams/mfa/email/challenge-response';
  static readonly EmailMfaDeleteDefaultEmailMfaPath = '/iams/mfa/email/default-email';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * When getting a challenge for the email MFA method, the user will be sent an email to the address in the associated
   * configuration
   * @param params The `EmailCodesMultiFactorAuthenticationService.EmailMfaGetChallengeParams` containing the following parameters:
   *
   * - `idToken`: Firebase Auth valid idToken that will be exchanged for a custom token alongside the challenge response
   *
   * - `configurationId`: id of the email method configuration for which to generate a challenge
   */
  EmailMfaGetChallengeResponse(params: EmailCodesMultiFactorAuthenticationService.EmailMfaGetChallengeParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.idToken != null) __headers = __headers.set('idToken', params.idToken.toString());
    if (params.configurationId != null) __params = __params.set('configurationId', params.configurationId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/iams/mfa/email/challenge`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * When getting a challenge for the email MFA method, the user will be sent an email to the address in the associated
   * configuration
   * @param params The `EmailCodesMultiFactorAuthenticationService.EmailMfaGetChallengeParams` containing the following parameters:
   *
   * - `idToken`: Firebase Auth valid idToken that will be exchanged for a custom token alongside the challenge response
   *
   * - `configurationId`: id of the email method configuration for which to generate a challenge
   */
  EmailMfaGetChallenge(params: EmailCodesMultiFactorAuthenticationService.EmailMfaGetChallengeParams): __Observable<null> {
    return this.EmailMfaGetChallengeResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Submit emailed codes to this endpoint to validate them against the user specified by the id token
   * @param params The `EmailCodesMultiFactorAuthenticationService.EmailMfaPostChallengeResponseParams` containing the following parameters:
   *
   * - `idToken`: Firebase Auth valid idToken that will be exchanged for a custom token alongside the challenge response
   *
   * - `body`: - { code: string TOTP code as sent to the user via email }
   *
   * @return Ok
   */
  EmailMfaPostChallengeResponseResponse(params: EmailCodesMultiFactorAuthenticationService.EmailMfaPostChallengeResponseParams): __Observable<__StrictHttpResponse<TubMfaChallengeResponseTokenResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.idToken != null) __headers = __headers.set('idToken', params.idToken.toString());
    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/iams/mfa/email/challenge-response`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubMfaChallengeResponseTokenResponse>;
      })
    );
  }
  /**
   * Submit emailed codes to this endpoint to validate them against the user specified by the id token
   * @param params The `EmailCodesMultiFactorAuthenticationService.EmailMfaPostChallengeResponseParams` containing the following parameters:
   *
   * - `idToken`: Firebase Auth valid idToken that will be exchanged for a custom token alongside the challenge response
   *
   * - `body`: - { code: string TOTP code as sent to the user via email }
   *
   * @return Ok
   */
  EmailMfaPostChallengeResponse(params: EmailCodesMultiFactorAuthenticationService.EmailMfaPostChallengeResponseParams): __Observable<TubMfaChallengeResponseTokenResponse> {
    return this.EmailMfaPostChallengeResponseResponse(params).pipe(
      __map(_r => _r.body as TubMfaChallengeResponseTokenResponse)
    );
  }

  /**
   * Deletes the default email mfa method configuration from the users account.
   * NOTE: This can only be used when there are no other methods available for the user to use on this account.
   * NOTE 2: This requires step up authentication
   */
  EmailMfaDeleteDefaultEmailMfaResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/iams/mfa/email/default-email`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Deletes the default email mfa method configuration from the users account.
   * NOTE: This can only be used when there are no other methods available for the user to use on this account.
   * NOTE 2: This requires step up authentication
   */
  EmailMfaDeleteDefaultEmailMfa(): __Observable<null> {
    return this.EmailMfaDeleteDefaultEmailMfaResponse().pipe(
      __map(_r => _r.body as null)
    );
  }
}

module EmailCodesMultiFactorAuthenticationService {

  /**
   * Parameters for EmailMfaGetChallenge
   */
  export interface EmailMfaGetChallengeParams {

    /**
     * Firebase Auth valid idToken that will be exchanged for a custom token alongside the challenge response
     */
    idToken: string;

    /**
     * id of the email method configuration for which to generate a challenge
     */
    configurationId: string;
  }

  /**
   * Parameters for EmailMfaPostChallengeResponse
   */
  export interface EmailMfaPostChallengeResponseParams {

    /**
     * Firebase Auth valid idToken that will be exchanged for a custom token alongside the challenge response
     */
    idToken: string;

    /**
     * - { code: string TOTP code as sent to the user via email }
     */
    body: {code?: string};
  }
}

export { EmailCodesMultiFactorAuthenticationService }
