/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { TubChatSendMessageRequestBody } from '../models/tub-chat-send-message-request-body';
@Injectable({
  providedIn: 'root',
})
class ChatsService extends __BaseService {
  static readonly ChatsSendChatMessagePath = '/chats/{chatSessionId}/messages/{chatMessageKey}';
  static readonly ChatsMarkAllAsReadPath = '/chats/{chatSessionId}/action/markAllAsRead';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Send the given message body into the chat session specified.
   * @param params The `ChatsService.ChatsSendChatMessageParams` containing the following parameters:
   *
   * - `messageBody`: request data, including text body of message to send, and the client timestamp for the message
   *
   * - `chatSessionId`: id of chat session into which to send message
   *
   * - `chatMessageKey`: string key to use as the key for submitting the message to the database, as generated by ref.push() on the client side
   */
  ChatsSendChatMessageResponse(params: ChatsService.ChatsSendChatMessageParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.messageBody;


    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/chats/${encodeURIComponent(String(params.chatSessionId))}/messages/${encodeURIComponent(String(params.chatMessageKey))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Send the given message body into the chat session specified.
   * @param params The `ChatsService.ChatsSendChatMessageParams` containing the following parameters:
   *
   * - `messageBody`: request data, including text body of message to send, and the client timestamp for the message
   *
   * - `chatSessionId`: id of chat session into which to send message
   *
   * - `chatMessageKey`: string key to use as the key for submitting the message to the database, as generated by ref.push() on the client side
   */
  ChatsSendChatMessage(params: ChatsService.ChatsSendChatMessageParams): __Observable<null> {
    return this.ChatsSendChatMessageResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Mark all messages as read on the given chat session
   * @param chatSessionId id of chat session for which to mark all messages as read
   */
  ChatsMarkAllAsReadResponse(chatSessionId: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/chats/${encodeURIComponent(String(chatSessionId))}/action/markAllAsRead`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Mark all messages as read on the given chat session
   * @param chatSessionId id of chat session for which to mark all messages as read
   */
  ChatsMarkAllAsRead(chatSessionId: string): __Observable<null> {
    return this.ChatsMarkAllAsReadResponse(chatSessionId).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module ChatsService {

  /**
   * Parameters for ChatsSendChatMessage
   */
  export interface ChatsSendChatMessageParams {

    /**
     * request data, including text body of message to send, and the client timestamp for the message
     */
    messageBody: TubChatSendMessageRequestBody;

    /**
     * id of chat session into which to send message
     */
    chatSessionId: string;

    /**
     * string key to use as the key for submitting the message to the database, as generated by ref.push() on the client side
     */
    chatMessageKey: string;
  }
}

export { ChatsService }
