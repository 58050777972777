import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, HostListener, Inject, Input, Renderer2 } from '@angular/core';
import { LIGHTBOX_EVENT, LightboxEvent } from './lightbox-event.service';
import * as i0 from "@angular/core";
import * as i1 from "./lightbox-event.service";
const _c0 = ["lb-overlay", ""];
export class LightboxOverlayComponent {
  constructor(_elemRef, _rendererRef, _lightboxEvent, _documentRef) {
    this._elemRef = _elemRef;
    this._rendererRef = _rendererRef;
    this._lightboxEvent = _lightboxEvent;
    this._documentRef = _documentRef;
    this.classList = 'lightboxOverlay animation fadeInOverlay';
    this._subscription = this._lightboxEvent.lightboxEvent$.subscribe(event => this._onReceivedEvent(event));
  }
  close() {
    // broadcast to itself and all others subscriber including the components
    this._lightboxEvent.broadcastLightboxEvent({
      id: LIGHTBOX_EVENT.CLOSE,
      data: null
    });
  }
  ngAfterViewInit() {
    const fadeDuration = this.options.fadeDuration;
    this._rendererRef.setStyle(this._elemRef.nativeElement, '-webkit-animation-duration', `${fadeDuration}s`);
    this._rendererRef.setStyle(this._elemRef.nativeElement, 'animation-duration', `${fadeDuration}s`);
    this._sizeOverlay();
  }
  onResize() {
    this._sizeOverlay();
  }
  ngOnDestroy() {
    this._subscription.unsubscribe();
  }
  _sizeOverlay() {
    const width = this._getOverlayWidth();
    const height = this._getOverlayHeight();
    this._rendererRef.setStyle(this._elemRef.nativeElement, 'width', `${width}px`);
    this._rendererRef.setStyle(this._elemRef.nativeElement, 'height', `${height}px`);
  }
  _onReceivedEvent(event) {
    switch (event.id) {
      case LIGHTBOX_EVENT.CLOSE:
        this._end();
        break;
      default:
        break;
    }
  }
  _end() {
    this.classList = 'lightboxOverlay animation fadeOutOverlay';
    // queue self destruction after the animation has finished
    // FIXME: not sure if there is any way better than this
    setTimeout(() => {
      this.cmpRef.destroy();
    }, this.options.fadeDuration * 1000);
  }
  _getOverlayWidth() {
    return Math.max(this._documentRef.body.scrollWidth, this._documentRef.body.offsetWidth, this._documentRef.documentElement.clientWidth, this._documentRef.documentElement.scrollWidth, this._documentRef.documentElement.offsetWidth);
  }
  _getOverlayHeight() {
    return Math.max(this._documentRef.body.scrollHeight, this._documentRef.body.offsetHeight, this._documentRef.documentElement.clientHeight, this._documentRef.documentElement.scrollHeight, this._documentRef.documentElement.offsetHeight);
  }
}
LightboxOverlayComponent.ɵfac = function LightboxOverlayComponent_Factory(t) {
  return new (t || LightboxOverlayComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i1.LightboxEvent), i0.ɵɵdirectiveInject(DOCUMENT));
};
LightboxOverlayComponent.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
  type: LightboxOverlayComponent,
  selectors: [["", "lb-overlay", ""]],
  hostVars: 2,
  hostBindings: function LightboxOverlayComponent_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("click", function LightboxOverlayComponent_click_HostBindingHandler() {
        return ctx.close();
      })("resize", function LightboxOverlayComponent_resize_HostBindingHandler() {
        return ctx.onResize();
      }, false, i0.ɵɵresolveWindow);
    }
    if (rf & 2) {
      i0.ɵɵclassMap(ctx.classList);
    }
  },
  inputs: {
    options: "options",
    cmpRef: "cmpRef"
  },
  attrs: _c0,
  decls: 0,
  vars: 0,
  template: function LightboxOverlayComponent_Template(rf, ctx) {},
  encapsulation: 2
});
(function () {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LightboxOverlayComponent, [{
    type: Component,
    args: [{
      selector: '[lb-overlay]',
      template: '',
      host: {
        '[class]': 'classList'
      }
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i1.LightboxEvent
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [DOCUMENT]
      }]
    }];
  }, {
    options: [{
      type: Input
    }],
    cmpRef: [{
      type: Input
    }],
    close: [{
      type: HostListener,
      args: ['click']
    }],
    onResize: [{
      type: HostListener,
      args: ['window:resize']
    }]
  });
})();
