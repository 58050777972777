import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OpenidLandingComponent } from './openid-landing/openid-landing.component';
import { LightboxModule } from 'ngx-lightbox';

const routes: Routes = [
  {
    path: ':client',
    component: OpenidLandingComponent
  }
];

@NgModule({
  imports: [ RouterModule.forChild(routes), LightboxModule ],
  exports: [ RouterModule ]
})
export class OpenIDRoutingModule { }
