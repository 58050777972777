import { NgModule } from '@angular/core';
import { Route, RouterModule, Routes } from '@angular/router';

import { PageNotFoundComponent } from './modules/shared/components/page-not-found/page-not-found.component';
import { OpenidLandingComponent } from './modules/openid/openid-landing/openid-landing.component';
import { OpenidLoginGuideComponent } from './modules/openid/openid-login-guide/openid-login-guide.component';
import { RegionErrorComponent } from './modules/shared/components/region-error/region-error.component';

/**
 * Routes for https://auth.thrive.uk.com
 */
const routes: Routes = [
  {
    path: '',
    pathMatch: 'prefix',
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'signup'
      },
      // This route is used for testing signup locally at https://localhost:4300/signup
      // Subdomains are not supported by localhost, so we use this path instead when developing locally
      {
        path: 'signup',
        loadChildren: () => import('./modules/signup/signup.module').then(m => m.SignupModule),
      },
      {
        path: 'openid/guide/:platform/:client',
        component: OpenidLoginGuideComponent
      },
      {
        path: 'openid/guide/:client',
        component: OpenidLoginGuideComponent
      },
      {
        path: 'openid/:client',
        component: OpenidLandingComponent
      },
      {
        path: 'regionerror',
        component: RegionErrorComponent
      },
      {
        path: '404',
        component: PageNotFoundComponent
      },
      {
        path: '**',
        redirectTo: '/404'
      }
    ]
  }
];

/**
 * Routes for https://signup.thrive.uk.com
 */
const signupRoutes: Routes = [
  // This is the route that the live signup pages will take: https://signup.thrive.uk.com/signup/
  {
    path: '',
    pathMatch: 'prefix',
    loadChildren: () => import('./modules/signup/signup.module').then(m => m.SignupModule),
  }
];

/**
 * Strip out "dev." and "stage." from the hostname URL
 */
function getHostname(): string {
  const rawHostname: string = window.location.hostname;
  let hostname: string;
  if (rawHostname.startsWith('stage.')) {
    hostname = rawHostname.substring(6);
  } else if (rawHostname.startsWith('dev.') || rawHostname.startsWith('aus.')) {
    hostname = rawHostname.substring(4);
  } else {
    hostname = rawHostname;
  }
  return hostname;
}

function getRoutesForHost(): Route[] {
  const hostname = getHostname();
  if (hostname === 'signup.thrive.uk.com') {
    return signupRoutes;
  } else {
    return routes;
  }
}

@NgModule({
  imports: [ RouterModule.forRoot(getRoutesForHost(), {}) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {
}
