/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { TubMfaChallengeResponseTokenResponse } from '../models/tub-mfa-challenge-response-token-response';
import { TubRegisterNewSecretParams } from '../models/tub-register-new-secret-params';
import { TubTotpMfaSecret } from '../models/tub-totp-mfa-secret';
@Injectable({
  providedIn: 'root',
})
class TimeBasedOneTimePasswordsMultiFactorAuthenticationService extends __BaseService {
  static readonly TotpMfaPostChallengeResponsePath = '/iams/mfa/totp/challenge-response';
  static readonly TotpMfaRegisterNewSecretPath = '/iams/mfa/totp/secrets';
  static readonly TotpMfaGetRegisteredSecretsPath = '/iams/mfa/totp/secrets';
  static readonly TotpMfaDeleteRegisteredSecretPath = '/iams/mfa/totp/secrets/{id}';
  static readonly TotpMfaRelabelRegisteredSecretPath = '/iams/mfa/totp/secrets/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Submit TOTP codes to this endpoint to validate them against the user specified by the id token
   * @param params The `TimeBasedOneTimePasswordsMultiFactorAuthenticationService.TotpMfaPostChallengeResponseParams` containing the following parameters:
   *
   * - `idToken`: Firebase Auth valid idToken that will be exchanged for a custom token alongside the challenge response
   *
   * - `body`: - { code: string TOTP code as generated by an authenticator app }
   *
   * @return Ok
   */
  TotpMfaPostChallengeResponseResponse(params: TimeBasedOneTimePasswordsMultiFactorAuthenticationService.TotpMfaPostChallengeResponseParams): __Observable<__StrictHttpResponse<TubMfaChallengeResponseTokenResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.idToken != null) __headers = __headers.set('idToken', params.idToken.toString());
    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/iams/mfa/totp/challenge-response`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubMfaChallengeResponseTokenResponse>;
      })
    );
  }
  /**
   * Submit TOTP codes to this endpoint to validate them against the user specified by the id token
   * @param params The `TimeBasedOneTimePasswordsMultiFactorAuthenticationService.TotpMfaPostChallengeResponseParams` containing the following parameters:
   *
   * - `idToken`: Firebase Auth valid idToken that will be exchanged for a custom token alongside the challenge response
   *
   * - `body`: - { code: string TOTP code as generated by an authenticator app }
   *
   * @return Ok
   */
  TotpMfaPostChallengeResponse(params: TimeBasedOneTimePasswordsMultiFactorAuthenticationService.TotpMfaPostChallengeResponseParams): __Observable<TubMfaChallengeResponseTokenResponse> {
    return this.TotpMfaPostChallengeResponseResponse(params).pipe(
      __map(_r => _r.body as TubMfaChallengeResponseTokenResponse)
    );
  }

  /**
   * Register a new secret to the users' account using the provided secret.  Before adding to the account, the supplied
   * code will be tested and validated against the secret to ensure that the user has successfully installed the secret
   * into their Authenticator App and are able to use it to generate valid codes.
   * NOTE: This method requires step-up authentication.
   * @param body
   */
  TotpMfaRegisterNewSecretResponse(body: TubRegisterNewSecretParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/iams/mfa/totp/secrets`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Register a new secret to the users' account using the provided secret.  Before adding to the account, the supplied
   * code will be tested and validated against the secret to ensure that the user has successfully installed the secret
   * into their Authenticator App and are able to use it to generate valid codes.
   * NOTE: This method requires step-up authentication.
   * @param body
   */
  TotpMfaRegisterNewSecret(body: TubRegisterNewSecretParams): __Observable<null> {
    return this.TotpMfaRegisterNewSecretResponse(body).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * List all time-based one time password secrets that have been registered against the authenticated user's account.
   * @return Ok
   */
  TotpMfaGetRegisteredSecretsResponse(): __Observable<__StrictHttpResponse<Array<TubTotpMfaSecret>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/iams/mfa/totp/secrets`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TubTotpMfaSecret>>;
      })
    );
  }
  /**
   * List all time-based one time password secrets that have been registered against the authenticated user's account.
   * @return Ok
   */
  TotpMfaGetRegisteredSecrets(): __Observable<Array<TubTotpMfaSecret>> {
    return this.TotpMfaGetRegisteredSecretsResponse().pipe(
      __map(_r => _r.body as Array<TubTotpMfaSecret>)
    );
  }

  /**
   * Delete the TOTP secret associated with the specified id from the authenticated user's account.
   * NOTE: This method requires step-up authentication.
   * @param id identifier of the particular secret to remove from the account
   */
  TotpMfaDeleteRegisteredSecretResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/iams/mfa/totp/secrets/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete the TOTP secret associated with the specified id from the authenticated user's account.
   * NOTE: This method requires step-up authentication.
   * @param id identifier of the particular secret to remove from the account
   */
  TotpMfaDeleteRegisteredSecret(id: string): __Observable<null> {
    return this.TotpMfaDeleteRegisteredSecretResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Apply a new label to an existing TOTP secret.
   * @param params The `TimeBasedOneTimePasswordsMultiFactorAuthenticationService.TotpMfaRelabelRegisteredSecretParams` containing the following parameters:
   *
   * - `id`: id of the existing secret to apply the label to
   *
   * - `body`: should contain a label field which should be any arbitrary string
   */
  TotpMfaRelabelRegisteredSecretResponse(params: TimeBasedOneTimePasswordsMultiFactorAuthenticationService.TotpMfaRelabelRegisteredSecretParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/iams/mfa/totp/secrets/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Apply a new label to an existing TOTP secret.
   * @param params The `TimeBasedOneTimePasswordsMultiFactorAuthenticationService.TotpMfaRelabelRegisteredSecretParams` containing the following parameters:
   *
   * - `id`: id of the existing secret to apply the label to
   *
   * - `body`: should contain a label field which should be any arbitrary string
   */
  TotpMfaRelabelRegisteredSecret(params: TimeBasedOneTimePasswordsMultiFactorAuthenticationService.TotpMfaRelabelRegisteredSecretParams): __Observable<null> {
    return this.TotpMfaRelabelRegisteredSecretResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module TimeBasedOneTimePasswordsMultiFactorAuthenticationService {

  /**
   * Parameters for TotpMfaPostChallengeResponse
   */
  export interface TotpMfaPostChallengeResponseParams {

    /**
     * Firebase Auth valid idToken that will be exchanged for a custom token alongside the challenge response
     */
    idToken: string;

    /**
     * - { code: string TOTP code as generated by an authenticator app }
     */
    body: {code?: string};
  }

  /**
   * Parameters for TotpMfaRelabelRegisteredSecret
   */
  export interface TotpMfaRelabelRegisteredSecretParams {

    /**
     * id of the existing secret to apply the label to
     */
    id: string;

    /**
     * should contain a label field which should be any arbitrary string
     */
    body: {label?: string};
  }
}

export { TimeBasedOneTimePasswordsMultiFactorAuthenticationService }
