import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { IAMSOpenIDService } from '../../../../backend-client/services/iamsopen-id.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { CustomTokenResponse } from '../../../../backend-client/models/custom-token-response';
import { TubErrorReportingService } from '../../shared/services/tub-error-reporting.service';

export enum OpenIDState {
  Loading,
  Authorised,
  Error
}

@Component({
  selector: 'app-openid-landing',
  templateUrl: './openid-landing.component.html',
  styleUrls: [ './openid-landing.component.scss' ]
})
export class OpenidLandingComponent implements OnInit {

  // state management
  private stateInternal: OpenIDState;
  public previousState: OpenIDState;
  private backAction: () => void;

  // UI bindings
  public errorMessage: string;
  public hide: any = true;
  public redirectLink: string;
  public param: {};

  // Parameters
  private clientID: string;

  get state(): OpenIDState {
    return this.stateInternal;
  }

  set state(value: OpenIDState) {
    if (this.stateInternal !== value) {
      this.previousState = this.stateInternal;
      this.stateInternal = value;
    }
  }

  public get openIDState(): typeof OpenIDState {
    return OpenIDState;
  }

  constructor(private route: ActivatedRoute,
              private openIDService: IAMSOpenIDService,
              private deviceService: DeviceDetectorService,
              private http: HttpClient,
              private tubErrorReportingService: TubErrorReportingService,
              private translate: TranslateService) {
    this.route.params.subscribe(params => {
      this.clientID = params.client;
    });
  }

  ngOnInit(): void {
    this.state = OpenIDState.Loading;
    this.route.queryParams.subscribe(async params => {

      const language = params.lang ?? 'en';
      this.translate.use(language).subscribe(() => {
        console.log(`Successfully set '${language}' language.'`);
      }, err => {
        console.error(`Problem with '${language}' language initialization.'`, err);
      }, async () => {
        if (params.error) {
          this.state = OpenIDState.Error;
          this.errorMessage = params.error_description;
          this.tubErrorReportingService.send('Failed to initialise Language. Error: ', params.error);
        } else {
          try {
            await this.completeLoginCallback(params);
          } catch (e) {
            this.state = OpenIDState.Error;
            if (e.error) {
              this.errorMessage = e.error;
            } else {
              this.errorMessage = JSON.stringify(e);
            }
            console.error(e);
            this.tubErrorReportingService.send('Failed to complete IAMS OpenID Login Callback. Error: ', e);
          }
        }
      });
    });
  }

  private async completeLoginCallback(params: Params): Promise<void> {

    let customTokenResponse: CustomTokenResponse;

    try {
      customTokenResponse = await this.openIDService.IamsOpenIDLoginCallback({
        state: params.state,
        clientID: this.clientID,
        code: params.code
      }).toPromise();
    } catch (error) {
      this.state = OpenIDState.Error;
      this.errorMessage = error.error;
      this.tubErrorReportingService.send('Failed to complete IAMS OpenID Login Callback. Error: ', error);
      return;
    }

    // This is the legacy version for Unity v3.13 and lower. It doesn't handle Unicode characters because btoa gets upset
    let base64 = null;
    try {
      base64 = this.customTokenToBase64(customTokenResponse);
    } catch (e) {
      // We need to support the legacy version in some capacity so convert the displayName and the first and last names to something safe
      // It will appear as garbage in the app but at least the user can log in
      // Take a copy of the object, so we don't mess up the original data
      const customTokenResponseClone = JSON.parse(JSON.stringify(customTokenResponse));
      if (customTokenResponseClone.user.displayName) {
        customTokenResponseClone.user.displayName = '';
      }
      if (customTokenResponseClone.patientRecord.firstName) {
        customTokenResponseClone.patientRecord.firstName = '';
      }
      if (customTokenResponseClone.patientRecord.lastName) {
        customTokenResponseClone.patientRecord.lastName = '';
      }
      base64 = this.customTokenToBase64(customTokenResponseClone);
    }
    const safeBase64 = this.customTokenToSafeBase64(customTokenResponse);

    this.state = OpenIDState.Authorised;

    // Check if request state has a redirect URL
    let redirectURL: string;
    // Save variables from state to return and verify request
    let stateVars: { expiresOn: string, redirectUrl: string };
    if (params.state) {
      stateVars = Object.values(JSON.parse(params.state))[0] as { expiresOn: string, redirectUrl: string };
      redirectURL = stateVars.redirectUrl;
    }

    this.redirectLink = params?.state && redirectURL ? `${redirectURL}?response=${base64}&${Object.keys(JSON.parse(params.state))}=${JSON.stringify(stateVars)}` : `feelstressfree://openid?response=${base64}&responseSafe=${safeBase64}`;

    if (this.deviceService.isDesktop()) {

      try {
        console.log('Attempting to process open id via mobile method....');
        window.location.href = this.redirectLink;
      } catch (error) {
        console.error('Failed to complete open id process via Mobile method. Error: ', error);
        this.tubErrorReportingService.send('Failed to complete open id process via Mobile method: ', error);
      }

      // Run for Apps only - ignore if dashboard called
      if (!params?.state && !redirectURL) {
        try {
          console.log('Attempting to process open id via WebGl method....');
          window.opener.postMessage(`openid?response=${base64}&responseSafe=${safeBase64}`, '*');
          window.close();
        } catch (error) {
          console.error('Failed to complete open id process via WebGl method. Error: ', error);
          this.tubErrorReportingService.send('Failed to complete open id process via WebGl method. Error: ', error);
        }
      }

    } else {
      console.log('Attempting to set window.location.href to ' + this.redirectLink);
      try {
        window.location.href = this.redirectLink;
      } catch (error) {
        console.error('Failed to complete open id process via window.location.href method. Error: ', error);
        this.tubErrorReportingService.send('Failed to complete open id process via window.location.href method. Error: ', error);
      }
    }
  }

  private customTokenToBase64(customTokenResponse: CustomTokenResponse): string {
    const resultString = JSON.stringify(customTokenResponse);
    console.log('Result: ' + resultString);
    const base64 = encodeURIComponent(btoa(resultString));
    return base64;
  }

  private customTokenToSafeBase64(customTokenResponse: CustomTokenResponse): string {
    const resultString = JSON.stringify(customTokenResponse);
    console.log('Result: ' + resultString);
    const base64 = encodeURIComponent(btoa(this.toBinary(resultString)));
    return base64;
  }

// convert a Unicode string to a string in which
  // each 16-bit unit occupies only one byte
  private toBinary(inputString: string) {
    const codeUnits = new Uint16Array(inputString.length);
    for (let i = 0; i < codeUnits.length; i++) {
      codeUnits[i] = inputString.charCodeAt(i);
    }
    const charCodes = new Uint8Array(codeUnits.buffer);
    let result = '';
    for (let i = 0; i < charCodes.byteLength; i++) {
      result += String.fromCharCode(charCodes[i]);
    }
    return result;
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      this.state = OpenIDState.Error;
      this.errorMessage = error.error;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      this.state = OpenIDState.Error;
      this.errorMessage = error.error;
    }
    // Return an observable with a user-facing error message.
    return throwError(
      'Something bad happened; please try again later.' + JSON.stringify(error));
  }

  public openLink() {
    window.location.href = this.redirectLink;
  }

  public backClicked = async () => {
    this.errorMessage = null;
    this.state = this.previousState;
    if (this.backAction) {
      this.backAction();
    }
  };
}
