import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, Subscription, throwError, timer } from 'rxjs';
import { catchError, retry, switchMap } from 'rxjs/operators';
import { IsMaintenanceBypassEnabled } from './maintenance';
import { environment } from '../../../../environments/environment';

export interface ServerStatus {
  goingOffline: boolean;
  offlineTime: number;
  pendingReason: string;
  activeReason: string;
}

@Injectable({
  providedIn: 'root'
})
export class ServerStatusService {

  constructor(private http: HttpClient) {
    this.statusURI = environment.serverStatusURI + '/server-status.json';
  }

  private readonly statusURI: string;
  private currentStatus: ServerStatus = { goingOffline: false, offlineTime: 0, pendingReason: null, activeReason: null };
  private lastCheckTime: number;

  subscription: Subscription;

  isServerOnline: BehaviorSubject<ServerStatus> = new BehaviorSubject<ServerStatus>(this.currentStatus);

  get isOnline(): boolean {
    const currentDate = new Date();
    const currentTime = currentDate.getTime();

    return !this.currentStatus.goingOffline || (this.currentStatus.goingOffline && this.currentStatus.offlineTime > currentTime);
  }

  get isGoingOffline(): boolean {
    const currentDate = new Date();
    const currentTime = currentDate.getTime();

    return this.currentStatus.goingOffline && this.currentStatus.offlineTime > currentTime;
  }

  private static handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }

  pollForServerStatus() {
    this.subscription = timer(0, 60000).pipe(
      switchMap(() => this.getStatus())
    ).subscribe((result: ServerStatus) => this.handleUpdatedStatus(result));
  }

  getStatus(): Promise<ServerStatus> | Observable<ServerStatus> {
    if (IsMaintenanceBypassEnabled()) {
      return new Promise(resolve => {
        resolve({ goingOffline: false, offlineTime: 3, pendingReason: null, activeReason: null });
      });
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const currentDate = new Date();
    const currentTime = currentDate.getTime();
    return this.http.get<ServerStatus>(this.statusURI + '?t=' + currentTime, httpOptions).pipe(
      retry(3), // retry a failed request up to 3 times
      catchError(ServerStatusService.handleError)
    );
  }

  private async handleUpdatedStatus(result: ServerStatus) {

    // If the server is back online, then reload the page to ensure no stale data
    if (this.currentStatus.goingOffline && !result.goingOffline) {
      location.href = '/authentication/login';
    }
    const currentDate = new Date();
    const currentTime = currentDate.getTime();
    let fireEvent = false;

    if (!result.goingOffline && this.currentStatus.goingOffline) {
      // We're no longer offline so fire event
      fireEvent = true;
    } else {
      // Are we already offline?
      if (result.goingOffline) {

        // We have gone offline
        if (result.offlineTime < currentTime &&
          (this.currentStatus.offlineTime >= this.lastCheckTime || !this.currentStatus.goingOffline)) {

          fireEvent = true;

        } else if (result.offlineTime >= currentTime &&
          (this.currentStatus.offlineTime < this.lastCheckTime || !this.currentStatus.goingOffline)) {

          fireEvent = true;
        }

      }
    }

    this.lastCheckTime = currentTime;
    this.currentStatus = result;
    if (fireEvent) {
      this.isServerOnline.next(result);
    }
  }
}
