/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { TubEtaggedPatientMetadata } from '../models/tub-etagged-patient-metadata';
import { TubMetadataPatchObject } from '../models/tub-metadata-patch-object';
@Injectable({
  providedIn: 'root',
})
class PatientMetadataService extends __BaseService {
  static readonly PatientMetadataGetAllMetadataPath = '/patient-metadata';
  static readonly PatientMetadataGetMetadataPath = '/patient-metadata/{metadataDocumentId}';
  static readonly PatientMetadataUpdateMetadataPath = '/patient-metadata/{metadataDocumentId}/action/update-metadata';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * request all patient meta data documents.
   * @return Ok
   */
  PatientMetadataGetAllMetadataResponse(): __Observable<__StrictHttpResponse<Array<TubEtaggedPatientMetadata>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/patient-metadata`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TubEtaggedPatientMetadata>>;
      })
    );
  }
  /**
   * request all patient meta data documents.
   * @return Ok
   */
  PatientMetadataGetAllMetadata(): __Observable<Array<TubEtaggedPatientMetadata>> {
    return this.PatientMetadataGetAllMetadataResponse().pipe(
      __map(_r => _r.body as Array<TubEtaggedPatientMetadata>)
    );
  }

  /**
   * request a specific patient metadata object.
   * @param metadataDocumentId the metadata object to request
   * @return Ok
   */
  PatientMetadataGetMetadataResponse(metadataDocumentId: string): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/patient-metadata/${encodeURIComponent(String(metadataDocumentId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * request a specific patient metadata object.
   * @param metadataDocumentId the metadata object to request
   * @return Ok
   */
  PatientMetadataGetMetadata(metadataDocumentId: string): __Observable<{}> {
    return this.PatientMetadataGetMetadataResponse(metadataDocumentId).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Updates one or more keys in an existing patient metadata object
   * @param params The `PatientMetadataService.PatientMetadataUpdateMetadataParams` containing the following parameters:
   *
   * - `metadataDocumentId`: the id of the metadata document
   *
   * - `metadataChangeList`: an array of key/value pairs to apply to the patients metadata document
   *
   * - `ETag`: arbitrary string received alongside the data when performing a get, used to ensure integrity
   */
  PatientMetadataUpdateMetadataResponse(params: PatientMetadataService.PatientMetadataUpdateMetadataParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.metadataChangeList;
    if (params.ETag != null) __headers = __headers.set('ETag', params.ETag.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/patient-metadata/${encodeURIComponent(String(params.metadataDocumentId))}/action/update-metadata`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Updates one or more keys in an existing patient metadata object
   * @param params The `PatientMetadataService.PatientMetadataUpdateMetadataParams` containing the following parameters:
   *
   * - `metadataDocumentId`: the id of the metadata document
   *
   * - `metadataChangeList`: an array of key/value pairs to apply to the patients metadata document
   *
   * - `ETag`: arbitrary string received alongside the data when performing a get, used to ensure integrity
   */
  PatientMetadataUpdateMetadata(params: PatientMetadataService.PatientMetadataUpdateMetadataParams): __Observable<null> {
    return this.PatientMetadataUpdateMetadataResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module PatientMetadataService {

  /**
   * Parameters for PatientMetadataUpdateMetadata
   */
  export interface PatientMetadataUpdateMetadataParams {

    /**
     * the id of the metadata document
     */
    metadataDocumentId: string;

    /**
     * an array of key/value pairs to apply to the patients metadata document
     */
    metadataChangeList: Array<TubMetadataPatchObject>;

    /**
     * arbitrary string received alongside the data when performing a get, used to ensure integrity
     */
    ETag: string;
  }
}

export { PatientMetadataService }
