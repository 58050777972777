import * as validate from 'validate.js';

interface ArrayValidatorOptions {
  presence?: boolean;
  unique?: boolean;
  type?: 'string';
  constraints?: any;
  length?: {
    minimum?: number;
    maximum?: number;
  };
}

validate.validators.array = async (value: any[], options: ArrayValidatorOptions) => {
  if (value == null) {
    return options?.presence === false ? null : 'does not exist';
  }

  if (!Array.isArray(value)) {
    return 'is not an array';
  }

  if (options.unique) {
    for (let index = 0; index < value.length; index++) {
      if (value.findIndex(element => element === value[index]) !== index) {
        return 'does not contain unique elements';
      }
    }
  }

  if (options?.length?.minimum && value.length < options.length.minimum) {
    return `is less than ${options.length.minimum}`;
  }

  if (options?.length?.maximum && value.length > options.length.maximum) {
    return `is greater than ${options.length.maximum}`;
  }

  if (options.type) {
    for (const element of value) {
      if (typeof element !== options.type) {
        return `does not fully contain type ${options.type}`;
      }
    }
  }

  if (options.constraints) {
    for (const element of value) {
      await validate.async(element, options.constraints);
    }
  }

  return null;
};
