/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { TubAccountDetailsSummary } from '../models/tub-account-details-summary';
import { TubPatient } from '../models/tub-patient';
@Injectable({
  providedIn: 'root',
})
class IAMSAccountDetailsService extends __BaseService {
  static readonly IamsAccountDetailsGetAccountDetailsSummaryPath = '/iams/account-details/summary';
  static readonly IamsAccountDetailsSetUserNamePath = '/iams/account-details/username';
  static readonly IamsAccountDetailsSetScreenNamePath = '/iams/account-details/screenname';
  static readonly IamsAccountDetailsSetLanguagePath = '/iams/account-details/language';
  static readonly IamsAccountDetailsGetPatientRecordPath = '/iams/account-details/patientrecord';
  static readonly IamsAccountDetailsChangeEmailAddressPath = '/iams/account-details/action/change-email';
  static readonly IamsAccountDetailsConfirmChangeEmailAddressPath = '/iams/account-details/action/confirm-change-email';
  static readonly IamsAccountDetailsRequestChangePasswordPath = '/iams/account-details/action/request-change-password';
  static readonly IamsAccountDetailsRequestDeletionPath = '/iams/account-details/action/request-deletion';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * retrieve summary of account details
   *
   * Fetches a simple summary of the account details for the currently authenticated user.
   * This data is closely related to the `CustomTokenResponse` data returned by the `/iams/auth/customToken` endpoint
   * @return Ok
   */
  IamsAccountDetailsGetAccountDetailsSummaryResponse(): __Observable<__StrictHttpResponse<TubAccountDetailsSummary>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/iams/account-details/summary`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubAccountDetailsSummary>;
      })
    );
  }
  /**
   * retrieve summary of account details
   *
   * Fetches a simple summary of the account details for the currently authenticated user.
   * This data is closely related to the `CustomTokenResponse` data returned by the `/iams/auth/customToken` endpoint
   * @return Ok
   */
  IamsAccountDetailsGetAccountDetailsSummary(): __Observable<TubAccountDetailsSummary> {
    return this.IamsAccountDetailsGetAccountDetailsSummaryResponse().pipe(
      __map(_r => _r.body as TubAccountDetailsSummary)
    );
  }

  /**
   * set the users real name
   *
   * This method sets the users real name on the patient record, therapist record and maybe google auth too?.
   * @param params The `IAMSAccountDetailsService.IamsAccountDetailsSetUserNameParams` containing the following parameters:
   *
   * - `lastName`: the users last name
   *
   * - `firstName`: the users first name
   */
  IamsAccountDetailsSetUserNameResponse(params: IAMSAccountDetailsService.IamsAccountDetailsSetUserNameParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.lastName != null) __params = __params.set('lastName', params.lastName.toString());
    if (params.firstName != null) __params = __params.set('firstName', params.firstName.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/iams/account-details/username`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * set the users real name
   *
   * This method sets the users real name on the patient record, therapist record and maybe google auth too?.
   * @param params The `IAMSAccountDetailsService.IamsAccountDetailsSetUserNameParams` containing the following parameters:
   *
   * - `lastName`: the users last name
   *
   * - `firstName`: the users first name
   */
  IamsAccountDetailsSetUserName(params: IAMSAccountDetailsService.IamsAccountDetailsSetUserNameParams): __Observable<null> {
    return this.IamsAccountDetailsSetUserNameResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * set the users screen name
   *
   * This method sets the users screen name on the user object.
   * @param screenName the users chosen screen name
   */
  IamsAccountDetailsSetScreenNameResponse(screenName: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (screenName != null) __params = __params.set('screenName', screenName.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/iams/account-details/screenname`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * set the users screen name
   *
   * This method sets the users screen name on the user object.
   * @param screenName the users chosen screen name
   */
  IamsAccountDetailsSetScreenName(screenName: string): __Observable<null> {
    return this.IamsAccountDetailsSetScreenNameResponse(screenName).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * set the users preferred language
   *
   * This method sets the users language on the patient record, therapist record and user record.
   * @param language the users 2 letter language code
   */
  IamsAccountDetailsSetLanguageResponse(language: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (language != null) __params = __params.set('language', language.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/iams/account-details/language`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * set the users preferred language
   *
   * This method sets the users language on the patient record, therapist record and user record.
   * @param language the users 2 letter language code
   */
  IamsAccountDetailsSetLanguage(language: string): __Observable<null> {
    return this.IamsAccountDetailsSetLanguageResponse(language).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * get the patient record details for the current user
   *
   * Retrieves the basic patient record information for the currently authenticated user.
   * @return Ok
   */
  IamsAccountDetailsGetPatientRecordResponse(): __Observable<__StrictHttpResponse<TubPatient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/iams/account-details/patientrecord`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubPatient>;
      })
    );
  }
  /**
   * get the patient record details for the current user
   *
   * Retrieves the basic patient record information for the currently authenticated user.
   * @return Ok
   */
  IamsAccountDetailsGetPatientRecord(): __Observable<TubPatient> {
    return this.IamsAccountDetailsGetPatientRecordResponse().pipe(
      __map(_r => _r.body as TubPatient)
    );
  }

  /**
   * request the change of this users email address
   *
   * This method allows the user to change their email address.
   * @param email the email we want to change our email to
   */
  IamsAccountDetailsChangeEmailAddressResponse(email: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (email != null) __params = __params.set('email', email.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/iams/account-details/action/change-email`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * request the change of this users email address
   *
   * This method allows the user to change their email address.
   * @param email the email we want to change our email to
   */
  IamsAccountDetailsChangeEmailAddress(email: string): __Observable<null> {
    return this.IamsAccountDetailsChangeEmailAddressResponse(email).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * confirm the users email change
   *
   * This method confirms the email change. It can be called unauthenticated as it is called from a confirm link via email which then opens a webpage which calls this endpoint.
   * @param id the id of the pending email change request
   */
  IamsAccountDetailsConfirmChangeEmailAddressResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (id != null) __params = __params.set('id', id.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/iams/account-details/action/confirm-change-email`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * confirm the users email change
   *
   * This method confirms the email change. It can be called unauthenticated as it is called from a confirm link via email which then opens a webpage which calls this endpoint.
   * @param id the id of the pending email change request
   */
  IamsAccountDetailsConfirmChangeEmailAddress(id: string): __Observable<null> {
    return this.IamsAccountDetailsConfirmChangeEmailAddressResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * request the change of this users password
   *
   * This method requests a password reset but doesnt actually change the password.
   * @param params The `IAMSAccountDetailsService.IamsAccountDetailsRequestChangePasswordParams` containing the following parameters:
   *
   * - `emailAddress`: email address of the account to request a password reset.
   *
   * - `language`: optional language. If not present, English will be used
   */
  IamsAccountDetailsRequestChangePasswordResponse(params: IAMSAccountDetailsService.IamsAccountDetailsRequestChangePasswordParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.emailAddress != null) __params = __params.set('emailAddress', params.emailAddress.toString());
    if (params.language != null) __params = __params.set('language', params.language.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/iams/account-details/action/request-change-password`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * request the change of this users password
   *
   * This method requests a password reset but doesnt actually change the password.
   * @param params The `IAMSAccountDetailsService.IamsAccountDetailsRequestChangePasswordParams` containing the following parameters:
   *
   * - `emailAddress`: email address of the account to request a password reset.
   *
   * - `language`: optional language. If not present, English will be used
   */
  IamsAccountDetailsRequestChangePassword(params: IAMSAccountDetailsService.IamsAccountDetailsRequestChangePasswordParams): __Observable<null> {
    return this.IamsAccountDetailsRequestChangePasswordResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
  IamsAccountDetailsRequestDeletionResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/iams/account-details/action/request-deletion`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }  IamsAccountDetailsRequestDeletion(): __Observable<null> {
    return this.IamsAccountDetailsRequestDeletionResponse().pipe(
      __map(_r => _r.body as null)
    );
  }
}

module IAMSAccountDetailsService {

  /**
   * Parameters for IamsAccountDetailsSetUserName
   */
  export interface IamsAccountDetailsSetUserNameParams {

    /**
     * the users last name
     */
    lastName: string;

    /**
     * the users first name
     */
    firstName: string;
  }

  /**
   * Parameters for IamsAccountDetailsRequestChangePassword
   */
  export interface IamsAccountDetailsRequestChangePasswordParams {

    /**
     * email address of the account to request a password reset.
     */
    emailAddress: string;

    /**
     * optional language. If not present, English will be used
     */
    language?: string;
  }
}

export { IAMSAccountDetailsService }
