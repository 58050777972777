/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { TubHasActiveChat } from '../models/tub-has-active-chat';
import { PubNubToken } from '../models/pub-nub-token';
import { TubChatHistory } from '../models/tub-chat-history';
@Injectable({
  providedIn: 'root',
})
class PatientChatService extends __BaseService {
  static readonly PatientChatRequestChatPath = '/patient/chat/request-chat';
  static readonly PatientChatRequestEmergencyChatPath = '/patient/chat/request-emergency-chat';
  static readonly PatientChatHasChatPath = '/patient/chat/exists';
  static readonly PatientChatTokenPath = '/patient/chat/token';
  static readonly PatientChatTokenDeletePath = '/patient/chat/token';
  static readonly PatientChatEndChatPath = '/patient/chat/end-chat';
  static readonly PatientChatGetV1ChatHistoryPath = '/patient/chat/v1/history';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Post a chat request for an authenticated patient.
   *
   * Post a chat request for an authenticated patient with a message. This will:
   *   - Create a chat request which therapists (who have access to see their requests) can view and accept.
   *   - Set up a realtime-database chat room for the patient and place them in there.
   *   - Create a start-chat moment.
   *   - Send a push notification to all therapists who have access to the patient's organisation.
   * @param params The `PatientChatService.PatientChatRequestChatParams` containing the following parameters:
   *
   * - `message`: Patient's message to show in the chat request.
   *
   * - `pubNub`: Flag to determine type of the chat.
   *
   * @return Ok
   */
  PatientChatRequestChatResponse(params: PatientChatService.PatientChatRequestChatParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.message != null) __params = __params.set('message', params.message.toString());
    if (params.pubNub != null) __params = __params.set('pubNub', params.pubNub.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/patient/chat/request-chat`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * Post a chat request for an authenticated patient.
   *
   * Post a chat request for an authenticated patient with a message. This will:
   *   - Create a chat request which therapists (who have access to see their requests) can view and accept.
   *   - Set up a realtime-database chat room for the patient and place them in there.
   *   - Create a start-chat moment.
   *   - Send a push notification to all therapists who have access to the patient's organisation.
   * @param params The `PatientChatService.PatientChatRequestChatParams` containing the following parameters:
   *
   * - `message`: Patient's message to show in the chat request.
   *
   * - `pubNub`: Flag to determine type of the chat.
   *
   * @return Ok
   */
  PatientChatRequestChat(params: PatientChatService.PatientChatRequestChatParams): __Observable<string> {
    return this.PatientChatRequestChatResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * Post an emergency chat request for an authenticated patient.
   *
   * Post an emergency chat request for an authenticated patient. It should only be possible to request an emergency chat if we are already in a chat
   * @param message Patient's message to show in the chat request.
   * @return Ok
   */
  PatientChatRequestEmergencyChatResponse(message: string): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (message != null) __params = __params.set('message', message.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/patient/chat/request-emergency-chat`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * Post an emergency chat request for an authenticated patient.
   *
   * Post an emergency chat request for an authenticated patient. It should only be possible to request an emergency chat if we are already in a chat
   * @param message Patient's message to show in the chat request.
   * @return Ok
   */
  PatientChatRequestEmergencyChat(message: string): __Observable<string> {
    return this.PatientChatRequestEmergencyChatResponse(message).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * Does the authenticated patient have an active chat.
   *
   * Does the authenticated patient have an active chat. This means that they:
   *   1) Have a start-chat as the latest chat moment.
   *   2) Have an active realtime-database chat room.
   *
   *   If a therapist has accepted the patient's chat request then the lead therapist's ID will also be included.
   * @param pubNub
   * @return Ok
   */
  PatientChatHasChatResponse(pubNub?: boolean): __Observable<__StrictHttpResponse<TubHasActiveChat>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (pubNub != null) __params = __params.set('pubNub', pubNub.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/patient/chat/exists`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubHasActiveChat>;
      })
    );
  }
  /**
   * Does the authenticated patient have an active chat.
   *
   * Does the authenticated patient have an active chat. This means that they:
   *   1) Have a start-chat as the latest chat moment.
   *   2) Have an active realtime-database chat room.
   *
   *   If a therapist has accepted the patient's chat request then the lead therapist's ID will also be included.
   * @param pubNub
   * @return Ok
   */
  PatientChatHasChat(pubNub?: boolean): __Observable<TubHasActiveChat> {
    return this.PatientChatHasChatResponse(pubNub).pipe(
      __map(_r => _r.body as TubHasActiveChat)
    );
  }

  /**
   * Requesting new token to access patient's chat
   * @return Ok
   */
  PatientChatTokenResponse(): __Observable<__StrictHttpResponse<PubNubToken>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/patient/chat/token`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PubNubToken>;
      })
    );
  }
  /**
   * Requesting new token to access patient's chat
   * @return Ok
   */
  PatientChatToken(): __Observable<PubNubToken> {
    return this.PatientChatTokenResponse().pipe(
      __map(_r => _r.body as PubNubToken)
    );
  }

  /**
   * Destroys token issued for the patient and prevents them to access chat channels
   * @param token token we want to remove
   * @return Ok
   */
  PatientChatTokenDeleteResponse(token: string): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (token != null) __params = __params.set('token', token.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/patient/chat/token`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * Destroys token issued for the patient and prevents them to access chat channels
   * @param token token we want to remove
   * @return Ok
   */
  PatientChatTokenDelete(token: string): __Observable<boolean> {
    return this.PatientChatTokenDeleteResponse(token).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * End the chat for an authenticated patient.
   */
  PatientChatEndChatResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/patient/chat/end-chat`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * End the chat for an authenticated patient.
   */
  PatientChatEndChat(): __Observable<null> {
    return this.PatientChatEndChatResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * get V1 chat history for patient.
   *
   * Get the full V1 chat history, including any ongoing chat, for the authenticated patient.
   * @return Ok
   */
  PatientChatGetV1ChatHistoryResponse(): __Observable<__StrictHttpResponse<TubChatHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/patient/chat/v1/history`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubChatHistory>;
      })
    );
  }
  /**
   * get V1 chat history for patient.
   *
   * Get the full V1 chat history, including any ongoing chat, for the authenticated patient.
   * @return Ok
   */
  PatientChatGetV1ChatHistory(): __Observable<TubChatHistory> {
    return this.PatientChatGetV1ChatHistoryResponse().pipe(
      __map(_r => _r.body as TubChatHistory)
    );
  }
}

module PatientChatService {

  /**
   * Parameters for PatientChatRequestChat
   */
  export interface PatientChatRequestChatParams {

    /**
     * Patient's message to show in the chat request.
     */
    message: string;

    /**
     * Flag to determine type of the chat.
     */
    pubNub?: boolean;
  }
}

export { PatientChatService }
