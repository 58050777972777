/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { RequestCallbackDetails } from '../models/request-callback-details';
import { TubCheckInRequest } from '../models/tub-check-in-request';
@Injectable({
  providedIn: 'root',
})
class ContactUsService extends __BaseService {
  static readonly ContactRequestAccessPath = '/contact/action/request-access/{type}';
  static readonly ContactRequestCallbackPath = '/contact/action/request-callback';
  static readonly ContactRequestCheckInPath = '/contact/action/request-check-in';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Request access to the Thrive App.
   * @param params The `ContactUsService.ContactRequestAccessParams` containing the following parameters:
   *
   * - `type`:
   *
   * - `message`:
   *
   * - `email`:
   *
   * - `isContactable`:
   */
  ContactRequestAccessResponse(params: ContactUsService.ContactRequestAccessParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.message != null) __params = __params.set('message', params.message.toString());
    if (params.email != null) __params = __params.set('email', params.email.toString());
    if (params.isContactable != null) __params = __params.set('isContactable', params.isContactable.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/contact/action/request-access/${encodeURIComponent(String(params.type))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Request access to the Thrive App.
   * @param params The `ContactUsService.ContactRequestAccessParams` containing the following parameters:
   *
   * - `type`:
   *
   * - `message`:
   *
   * - `email`:
   *
   * - `isContactable`:
   */
  ContactRequestAccess(params: ContactUsService.ContactRequestAccessParams): __Observable<null> {
    return this.ContactRequestAccessResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Request a callback.
   * @param params The `ContactUsService.ContactRequestCallbackParams` containing the following parameters:
   *
   * - `token`:
   *
   * - `clientDetails`:
   *
   * - `callbackGroup`:
   */
  ContactRequestCallbackResponse(params: ContactUsService.ContactRequestCallbackParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.token != null) __params = __params.set('token', params.token.toString());
    __body = params.clientDetails;
    if (params.callbackGroup != null) __params = __params.set('callbackGroup', params.callbackGroup.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/contact/action/request-callback`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Request a callback.
   * @param params The `ContactUsService.ContactRequestCallbackParams` containing the following parameters:
   *
   * - `token`:
   *
   * - `clientDetails`:
   *
   * - `callbackGroup`:
   */
  ContactRequestCallback(params: ContactUsService.ContactRequestCallbackParams): __Observable<null> {
    return this.ContactRequestCallbackResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Request a check-in with a colleague.
   * @param params The `ContactUsService.ContactRequestCheckInParams` containing the following parameters:
   *
   * - `token`: The query string token used to authenticate the request. Operation fails if this is invalid.
   *
   * - `request`: The check-in request details.
   */
  ContactRequestCheckInResponse(params: ContactUsService.ContactRequestCheckInParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.token != null) __params = __params.set('token', params.token.toString());
    __body = params.request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/contact/action/request-check-in`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Request a check-in with a colleague.
   * @param params The `ContactUsService.ContactRequestCheckInParams` containing the following parameters:
   *
   * - `token`: The query string token used to authenticate the request. Operation fails if this is invalid.
   *
   * - `request`: The check-in request details.
   */
  ContactRequestCheckIn(params: ContactUsService.ContactRequestCheckInParams): __Observable<null> {
    return this.ContactRequestCheckInResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module ContactUsService {

  /**
   * Parameters for ContactRequestAccess
   */
  export interface ContactRequestAccessParams {
    type: string;
    message: string;
    email: string;
    isContactable?: boolean;
  }

  /**
   * Parameters for ContactRequestCallback
   */
  export interface ContactRequestCallbackParams {
    token: string;
    clientDetails: RequestCallbackDetails;
    callbackGroup?: string;
  }

  /**
   * Parameters for ContactRequestCheckIn
   */
  export interface ContactRequestCheckInParams {

    /**
     * The query string token used to authenticate the request. Operation fails if this is invalid.
     */
    token: string;

    /**
     * The check-in request details.
     */
    request: TubCheckInRequest;
  }
}

export { ContactUsService }
