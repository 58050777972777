/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { TubReward } from '../models/tub-reward';
@Injectable({
  providedIn: 'root',
})
class RewardsService extends __BaseService {
  static readonly RewardsEarnPath = '/rewards/earn';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * request a new reward.
   * @param params The `RewardsService.RewardsEarnParams` containing the following parameters:
   *
   * - `isRecommended`: is this your current goal
   *
   * - `duration`: the time in minutes this session lasted. Capped at 20 minutes
   *
   * - `sessionId`: string id of the session
   *
   * @return Ok
   */
  RewardsEarnResponse(params: RewardsService.RewardsEarnParams): __Observable<__StrictHttpResponse<TubReward>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.isRecommended != null) __params = __params.set('isRecommended', params.isRecommended.toString());
    if (params.duration != null) __params = __params.set('duration', params.duration.toString());
    if (params.sessionId != null) __params = __params.set('sessionId', params.sessionId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/rewards/earn`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubReward>;
      })
    );
  }
  /**
   * request a new reward.
   * @param params The `RewardsService.RewardsEarnParams` containing the following parameters:
   *
   * - `isRecommended`: is this your current goal
   *
   * - `duration`: the time in minutes this session lasted. Capped at 20 minutes
   *
   * - `sessionId`: string id of the session
   *
   * @return Ok
   */
  RewardsEarn(params: RewardsService.RewardsEarnParams): __Observable<TubReward> {
    return this.RewardsEarnResponse(params).pipe(
      __map(_r => _r.body as TubReward)
    );
  }
}

module RewardsService {

  /**
   * Parameters for RewardsEarn
   */
  export interface RewardsEarnParams {

    /**
     * is this your current goal
     */
    isRecommended: boolean;

    /**
     * the time in minutes this session lasted. Capped at 20 minutes
     */
    duration: number;

    /**
     * string id of the session
     */
    sessionId?: string;
  }
}

export { RewardsService }
