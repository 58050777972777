/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { TubCloudDocument } from '../models/tub-cloud-document';
@Injectable({
  providedIn: 'root',
})
class CloudDocumentsService extends __BaseService {
  static readonly CloudDocumentGetPath = '/cloud-document/{name}';
  static readonly CloudDocumentSetPath = '/cloud-document/{name}';
  static readonly CloudDocumentGetTimestampPath = '/cloud-document/{name}/timestamp';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Retrieves a Cloud Document
   *
   * Retrieves the full Cloud Document (including last updated timestamp) for the current user with the given Name.
   * @param params The `CloudDocumentsService.CloudDocumentGetParams` containing the following parameters:
   *
   * - `name`: The name of the document to act upon
   *
   * - `dataStructureVersion`: The version of the data structure that the document is expected to conform to
   *
   * @return Ok
   */
  CloudDocumentGetResponse(params: CloudDocumentsService.CloudDocumentGetParams): __Observable<__StrictHttpResponse<TubCloudDocument>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.dataStructureVersion != null) __params = __params.set('dataStructureVersion', params.dataStructureVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/cloud-document/${encodeURIComponent(String(params.name))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubCloudDocument>;
      })
    );
  }
  /**
   * Retrieves a Cloud Document
   *
   * Retrieves the full Cloud Document (including last updated timestamp) for the current user with the given Name.
   * @param params The `CloudDocumentsService.CloudDocumentGetParams` containing the following parameters:
   *
   * - `name`: The name of the document to act upon
   *
   * - `dataStructureVersion`: The version of the data structure that the document is expected to conform to
   *
   * @return Ok
   */
  CloudDocumentGet(params: CloudDocumentsService.CloudDocumentGetParams): __Observable<TubCloudDocument> {
    return this.CloudDocumentGetResponse(params).pipe(
      __map(_r => _r.body as TubCloudDocument)
    );
  }

  /**
   * Attempt to create or replace the entire contents of a cloud document.
   *
   * Attempts to set the full Cloud Document to Value for the current user with the given Name.
   * If the provided Timestamp does not match the timestamp of the document on the server, then the request will
   * be rejected as this suggests another change has been made to it remotely.
   * In the event of a conflict, this will return the result of a GetDocument call.
   * @param params The `CloudDocumentsService.CloudDocumentSetParams` containing the following parameters:
   *
   * - `name`: The name of the document to act upon
   *
   * - `dataStructureVersion`: The version of the data structure that the document is expected to conform to
   *
   * - `content`: An object representing the full contents of the document
   *
   * - `timestamp`: The timestamp associated with the last retrieval of the document
   *
   * @return Ok
   */
  CloudDocumentSetResponse(params: CloudDocumentsService.CloudDocumentSetParams): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.dataStructureVersion != null) __params = __params.set('dataStructureVersion', params.dataStructureVersion.toString());
    __body = params.content;
    if (params.timestamp != null) __params = __params.set('timestamp', params.timestamp.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/cloud-document/${encodeURIComponent(String(params.name))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: parseFloat((_r as HttpResponse<any>).body as string) }) as __StrictHttpResponse<number>
      })
    );
  }
  /**
   * Attempt to create or replace the entire contents of a cloud document.
   *
   * Attempts to set the full Cloud Document to Value for the current user with the given Name.
   * If the provided Timestamp does not match the timestamp of the document on the server, then the request will
   * be rejected as this suggests another change has been made to it remotely.
   * In the event of a conflict, this will return the result of a GetDocument call.
   * @param params The `CloudDocumentsService.CloudDocumentSetParams` containing the following parameters:
   *
   * - `name`: The name of the document to act upon
   *
   * - `dataStructureVersion`: The version of the data structure that the document is expected to conform to
   *
   * - `content`: An object representing the full contents of the document
   *
   * - `timestamp`: The timestamp associated with the last retrieval of the document
   *
   * @return Ok
   */
  CloudDocumentSet(params: CloudDocumentsService.CloudDocumentSetParams): __Observable<number> {
    return this.CloudDocumentSetResponse(params).pipe(
      __map(_r => _r.body as number)
    );
  }

  /**
   * Retrieve the current last updated timestamp on the document
   *
   * Retrieves the current last updated timestamp on the document for the current user with the given Name.
   * @param params The `CloudDocumentsService.CloudDocumentGetTimestampParams` containing the following parameters:
   *
   * - `name`: The name of the document to act upon
   *
   * - `dataStructureVersion`: The version of the data structure that the document is expected to conform to
   *
   * @return Ok
   */
  CloudDocumentGetTimestampResponse(params: CloudDocumentsService.CloudDocumentGetTimestampParams): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.dataStructureVersion != null) __params = __params.set('dataStructureVersion', params.dataStructureVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/cloud-document/${encodeURIComponent(String(params.name))}/timestamp`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: parseFloat((_r as HttpResponse<any>).body as string) }) as __StrictHttpResponse<number>
      })
    );
  }
  /**
   * Retrieve the current last updated timestamp on the document
   *
   * Retrieves the current last updated timestamp on the document for the current user with the given Name.
   * @param params The `CloudDocumentsService.CloudDocumentGetTimestampParams` containing the following parameters:
   *
   * - `name`: The name of the document to act upon
   *
   * - `dataStructureVersion`: The version of the data structure that the document is expected to conform to
   *
   * @return Ok
   */
  CloudDocumentGetTimestamp(params: CloudDocumentsService.CloudDocumentGetTimestampParams): __Observable<number> {
    return this.CloudDocumentGetTimestampResponse(params).pipe(
      __map(_r => _r.body as number)
    );
  }
}

module CloudDocumentsService {

  /**
   * Parameters for CloudDocumentGet
   */
  export interface CloudDocumentGetParams {

    /**
     * The name of the document to act upon
     */
    name: string;

    /**
     * The version of the data structure that the document is expected to conform to
     */
    dataStructureVersion?: number;
  }

  /**
   * Parameters for CloudDocumentSet
   */
  export interface CloudDocumentSetParams {

    /**
     * The name of the document to act upon
     */
    name: string;

    /**
     * The version of the data structure that the document is expected to conform to
     */
    dataStructureVersion: number;

    /**
     * An object representing the full contents of the document
     */
    content: {};

    /**
     * The timestamp associated with the last retrieval of the document
     */
    timestamp?: number;
  }

  /**
   * Parameters for CloudDocumentGetTimestamp
   */
  export interface CloudDocumentGetTimestampParams {

    /**
     * The name of the document to act upon
     */
    name: string;

    /**
     * The version of the data structure that the document is expected to conform to
     */
    dataStructureVersion: number;
  }
}

export { CloudDocumentsService }
