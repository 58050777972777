import { ErrorHandler, Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { MessageService } from '../modules/shared/services/message.service';
import { TubErrorReportingService } from '../modules/shared/services/tub-error-reporting.service';

@Injectable()
export class DashboardErrorHandler implements ErrorHandler {
  constructor(private messageService: MessageService,
              private tubErrorReportingService: TubErrorReportingService,
              public readonly translateService: TranslateService,
              ) {}

  handleError(error: any): void {
    const errorMessage = this.translateService.instant('Error_SomethingWentWrong');
    this.messageService.showMessage(errorMessage);
    console.error(error);
    this.tubErrorReportingService.send('Uncaught exception', error);
  }
}
