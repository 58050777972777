/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
class EmailControllerService extends __BaseService {
  static readonly EmailSubscribePath = '/email/subscribe/{email}';
  static readonly EmailUnsubscribePath = '/email/unsubscribe/{email}';
  static readonly EmailUpdateSubscriptionWithJwtPath = '/email/update/jwt/{token}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Enable an email subscription for an authenticated user.
   * @param email The email to subscribe to.
   */
  EmailSubscribeResponse(email: 'weekly-engagement' | 'weekly-digest' | 'feedback-survey'): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/email/subscribe/${encodeURIComponent(String(email))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Enable an email subscription for an authenticated user.
   * @param email The email to subscribe to.
   */
  EmailSubscribe(email: 'weekly-engagement' | 'weekly-digest' | 'feedback-survey'): __Observable<null> {
    return this.EmailSubscribeResponse(email).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Disable an email subscription for an authenticated user.
   * @param email The email to un-subscribed from.
   */
  EmailUnsubscribeResponse(email: 'weekly-engagement' | 'weekly-digest' | 'feedback-survey'): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/email/unsubscribe/${encodeURIComponent(String(email))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Disable an email subscription for an authenticated user.
   * @param email The email to un-subscribed from.
   */
  EmailUnsubscribe(email: 'weekly-engagement' | 'weekly-digest' | 'feedback-survey'): __Observable<null> {
    return this.EmailUnsubscribeResponse(email).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Update a user's email subscription using a JWT.
   *
   * Update a user's email subscription using a JWT. Note that this **must** remain public, as the use case is from a email link.
   * @param token The encrypted JWT token containing the subscription changes.
   * @return Ok
   */
  EmailUpdateSubscriptionWithJwtResponse(token: string): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/email/update/jwt/${encodeURIComponent(String(token))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * Update a user's email subscription using a JWT.
   *
   * Update a user's email subscription using a JWT. Note that this **must** remain public, as the use case is from a email link.
   * @param token The encrypted JWT token containing the subscription changes.
   * @return Ok
   */
  EmailUpdateSubscriptionWithJwt(token: string): __Observable<string> {
    return this.EmailUpdateSubscriptionWithJwtResponse(token).pipe(
      __map(_r => _r.body as string)
    );
  }
}

module EmailControllerService {
}

export { EmailControllerService }
