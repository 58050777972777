import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { firstValueFrom, Observable } from 'rxjs';

import { GuideConfiguration } from './guide-configuration';

@Injectable({
  providedIn: 'root'
})
export class GuideConfigService {

  private loadedConfig: string;
  private currentConfig: GuideConfiguration;

  constructor(private httpClient: HttpClient) {}

  public async getConfig(clientId: string): Promise<GuideConfiguration> {
    if (this.loadedConfig === clientId) {
      return this.currentConfig;
    }
    this.loadedConfig = clientId;
    this.currentConfig = await firstValueFrom(this.getJSON(`assets/guide/${clientId}/config.json`));
    return this.currentConfig;
  }

  private getJSON(url: string): Observable<any> {
    return this.httpClient.get(url);
  }
}
