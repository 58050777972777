/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
class SocialService extends __BaseService {
  static readonly SocialSendWelfareEmailPath = '/social/welfareEmail';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * request a welfare email. In the wrong place?
   * @param params The `SocialService.SocialSendWelfareEmailParams` containing the following parameters:
   *
   * - `targetEmailAddresses`:
   *
   * - `careFirstStyleEmail`:
   */
  SocialSendWelfareEmailResponse(params: SocialService.SocialSendWelfareEmailParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.targetEmailAddresses || []).forEach(val => {if (val != null) __params = __params.append('targetEmailAddresses', val.toString())});
    if (params.careFirstStyleEmail != null) __params = __params.set('careFirstStyleEmail', params.careFirstStyleEmail.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/social/welfareEmail`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * request a welfare email. In the wrong place?
   * @param params The `SocialService.SocialSendWelfareEmailParams` containing the following parameters:
   *
   * - `targetEmailAddresses`:
   *
   * - `careFirstStyleEmail`:
   */
  SocialSendWelfareEmail(params: SocialService.SocialSendWelfareEmailParams): __Observable<null> {
    return this.SocialSendWelfareEmailResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module SocialService {

  /**
   * Parameters for SocialSendWelfareEmail
   */
  export interface SocialSendWelfareEmailParams {
    targetEmailAddresses: Array<string>;
    careFirstStyleEmail: boolean;
  }
}

export { SocialService }
