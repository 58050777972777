import * as i0 from '@angular/core';
import { InjectionToken, PLATFORM_ID, Injectable, Inject, Optional, NgModule } from '@angular/core';
import * as i1 from '@angular/fire';
import { VERSION } from '@angular/fire';
import { ɵfirebaseAppFactory as _firebaseAppFactory, ɵcacheInstance as _cacheInstance, ɵlazySDKProxy as _lazySDKProxy, FIREBASE_OPTIONS, FIREBASE_APP_NAME, ɵapplyMixins as _applyMixins } from '@angular/fire/compat';
import { isSupported } from 'firebase/messaging';
import { of, EMPTY, Observable, concat } from 'rxjs';
import { subscribeOn, observeOn, switchMap, map, shareReplay, switchMapTo, catchError, mergeMap, defaultIfEmpty } from 'rxjs/operators';
import firebase from 'firebase/compat/app';

// DO NOT MODIFY, this file is autogenerated by tools/build.ts
// Export a null object with the same keys as firebase/compat/messaging, so Proxy can work with proxy-polyfill in Internet Explorer
const proxyPolyfillCompat = {
  deleteToken: null,
  getToken: null,
  onMessage: null,
  onBackgroundMessage: null
};
const VAPID_KEY = new InjectionToken('angularfire2.messaging.vapid-key');
const SERVICE_WORKER = new InjectionToken('angularfire2.messaging.service-worker-registeration');
class AngularFireMessaging {
  requestPermission;
  getToken;
  tokenChanges;
  messages;
  requestToken;
  deleteToken;
  constructor(options, name,
  // eslint-disable-next-line @typescript-eslint/ban-types
  platformId, zone, schedulers, vapidKey, _serviceWorker) {
    const serviceWorker = _serviceWorker;
    const messaging = of(undefined).pipe(subscribeOn(schedulers.outsideAngular), observeOn(schedulers.insideAngular), switchMap(isSupported), switchMap(supported => supported ? import('firebase/compat/messaging') : EMPTY), map(() => _firebaseAppFactory(options, zone, name)), switchMap(app => _cacheInstance(`${app.name}.messaging`, 'AngularFireMessaging', app.name, () => {
      return of(app.messaging());
    }, [])), shareReplay({
      bufferSize: 1,
      refCount: false
    }));
    this.requestPermission = messaging.pipe(subscribeOn(schedulers.outsideAngular), observeOn(schedulers.insideAngular), switchMap(() => Notification.requestPermission()));
    this.getToken = messaging.pipe(subscribeOn(schedulers.outsideAngular), observeOn(schedulers.insideAngular), switchMap(async messaging => {
      if (Notification.permission === 'granted') {
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        const serviceWorkerRegistration = serviceWorker ? await serviceWorker : null;
        return await messaging.getToken({
          vapidKey,
          serviceWorkerRegistration
        });
      } else {
        return null;
      }
    }));
    const notificationPermission$ = new Observable(emitter => {
      navigator.permissions.query({
        name: 'notifications'
      }).then(notificationPerm => {
        notificationPerm.onchange = () => emitter.next();
      });
    });
    const tokenChange$ = messaging.pipe(subscribeOn(schedulers.outsideAngular), observeOn(schedulers.insideAngular), switchMapTo(notificationPermission$), switchMapTo(this.getToken));
    this.tokenChanges = messaging.pipe(subscribeOn(schedulers.outsideAngular), observeOn(schedulers.insideAngular), switchMap(() => concat(this.getToken, tokenChange$)));
    this.messages = messaging.pipe(subscribeOn(schedulers.outsideAngular), observeOn(schedulers.insideAngular), switchMap(messaging => new Observable(emitter => messaging.onMessage(emitter))));
    this.requestToken = messaging.pipe(subscribeOn(schedulers.outsideAngular), observeOn(schedulers.insideAngular), switchMap(() => this.requestPermission), catchError(() => of(null)), mergeMap(() => this.tokenChanges));
    this.deleteToken = () => messaging.pipe(subscribeOn(schedulers.outsideAngular), observeOn(schedulers.insideAngular), switchMap(messaging => messaging.deleteToken()), defaultIfEmpty(false));
    return _lazySDKProxy(this, messaging, zone);
  }
  static ɵfac = function AngularFireMessaging_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || AngularFireMessaging)(i0.ɵɵinject(FIREBASE_OPTIONS), i0.ɵɵinject(FIREBASE_APP_NAME, 8), i0.ɵɵinject(PLATFORM_ID), i0.ɵɵinject(i0.NgZone), i0.ɵɵinject(i1.ɵAngularFireSchedulers), i0.ɵɵinject(VAPID_KEY, 8), i0.ɵɵinject(SERVICE_WORKER, 8));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: AngularFireMessaging,
    factory: AngularFireMessaging.ɵfac,
    providedIn: 'any'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AngularFireMessaging, [{
    type: Injectable,
    args: [{
      providedIn: 'any'
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [FIREBASE_OPTIONS]
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [FIREBASE_APP_NAME]
    }]
  }, {
    type: Object,
    decorators: [{
      type: Inject,
      args: [PLATFORM_ID]
    }]
  }, {
    type: i0.NgZone
  }, {
    type: i1.ɵAngularFireSchedulers
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [VAPID_KEY]
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [SERVICE_WORKER]
    }]
  }], null);
})();
_applyMixins(AngularFireMessaging, [proxyPolyfillCompat]);
class AngularFireMessagingModule {
  constructor() {
    firebase.registerVersion('angularfire', VERSION.full, 'fcm-compat');
  }
  static ɵfac = function AngularFireMessagingModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || AngularFireMessagingModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: AngularFireMessagingModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    providers: [AngularFireMessaging]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AngularFireMessagingModule, [{
    type: NgModule,
    args: [{
      providers: [AngularFireMessaging]
    }]
  }], () => [], null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { AngularFireMessaging, AngularFireMessagingModule, SERVICE_WORKER, VAPID_KEY };
