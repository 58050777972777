import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Injectable } from '@angular/core';
import { InjectableEnvironmentService } from './injectable-environment-service';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService extends InjectableEnvironmentService {

  constructor(http: HttpClient) {
    super(http);
  }

  getEnvironment(): any {
    return environment;
  }
}
