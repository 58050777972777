import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RouterResolveStatusService {

  private isLoading = false;

  public get IsLoading() {
    return this.isLoading;
  }
}
