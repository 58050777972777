/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { MessageResponse } from '../models/message-response';
import { TubAdminMessageAlert } from '../models/tub-admin-message-alert';
import { TubAdminMessageAlertImmutable } from '../models/tub-admin-message-alert-immutable';
@Injectable({
  providedIn: 'root',
})
class MessageAlertsService extends __BaseService {
  static readonly MessageAlertsGetMessagesPath = '/message-alerts';
  static readonly MessageAlertsCreateMessagePath = '/message-alerts';
  static readonly MessageAlertsGetMessagePath = '/message-alerts/{id}';
  static readonly MessageAlertsUpdateMessagePath = '/message-alerts/{id}';
  static readonly MessageAlertsDeleteMessagePath = '/message-alerts/{id}';
  static readonly MessageAlertsControllerV2ListMessagesForAuthenticatedUserPath = '/v2/message-alerts/action/list-messages-for-user';
  static readonly MessageAlertsControllerV2ListMessagesForPublicPath = '/v2/message-alerts/action/list-messages-for-public';
  static readonly MessageAlertsControllerV2GetMessagesPath = '/v2/message-alerts';
  static readonly MessageAlertsControllerV2CreateMessagePath = '/v2/message-alerts';
  static readonly MessageAlertsControllerV2GetMessagePath = '/v2/message-alerts/{id}';
  static readonly MessageAlertsControllerV2UpdateMessagePath = '/v2/message-alerts/{id}';
  static readonly MessageAlertsControllerV2DeleteMessagePath = '/v2/message-alerts/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * get message alerts for the app to display
   *
   * Retrieves all relevant message alerts for the user in their current context.  For an unauthenticated user, this
   * will be all message alerts currently scheduled to appear at the time of calling this endpoint, whose version number
   * range matches this users app version, and where the client list has been left unspecified (global to all clients).
   * If the user is authenticated, then the behaviour is the same except that they will also possibly see any message
   * alerts specific to their organisation membership.
   * @param version the clients app version
   * @return Ok
   */
  MessageAlertsGetMessagesResponse(version: number): __Observable<__StrictHttpResponse<MessageResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (version != null) __params = __params.set('version', version.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/message-alerts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MessageResponse>;
      })
    );
  }
  /**
   * get message alerts for the app to display
   *
   * Retrieves all relevant message alerts for the user in their current context.  For an unauthenticated user, this
   * will be all message alerts currently scheduled to appear at the time of calling this endpoint, whose version number
   * range matches this users app version, and where the client list has been left unspecified (global to all clients).
   * If the user is authenticated, then the behaviour is the same except that they will also possibly see any message
   * alerts specific to their organisation membership.
   * @param version the clients app version
   * @return Ok
   */
  MessageAlertsGetMessages(version: number): __Observable<MessageResponse> {
    return this.MessageAlertsGetMessagesResponse(version).pipe(
      __map(_r => _r.body as MessageResponse)
    );
  }

  /**
   * create a new message alert
   *
   * *
   * @param message FsMessageAlert data
   */
  MessageAlertsCreateMessageResponse(message: TubAdminMessageAlert): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = message;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/message-alerts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * create a new message alert
   *
   * *
   * @param message FsMessageAlert data
   */
  MessageAlertsCreateMessage(message: TubAdminMessageAlert): __Observable<null> {
    return this.MessageAlertsCreateMessageResponse(message).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * get a message by documentRef
   *
   * *
   * @param id the message to fetch
   * @return Ok
   */
  MessageAlertsGetMessageResponse(id: string): __Observable<__StrictHttpResponse<TubAdminMessageAlertImmutable>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/message-alerts/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubAdminMessageAlertImmutable>;
      })
    );
  }
  /**
   * get a message by documentRef
   *
   * *
   * @param id the message to fetch
   * @return Ok
   */
  MessageAlertsGetMessage(id: string): __Observable<TubAdminMessageAlertImmutable> {
    return this.MessageAlertsGetMessageResponse(id).pipe(
      __map(_r => _r.body as TubAdminMessageAlertImmutable)
    );
  }

  /**
   * update a message
   *
   * *
   * @param params The `MessageAlertsService.MessageAlertsUpdateMessageParams` containing the following parameters:
   *
   * - `message`: FsMessageAlert data
   *
   * - `id`: the message to fetch
   *
   * - `ETag`: eTag of last update time returned from get Function
   */
  MessageAlertsUpdateMessageResponse(params: MessageAlertsService.MessageAlertsUpdateMessageParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.message;

    if (params.ETag != null) __headers = __headers.set('ETag', params.ETag.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/message-alerts/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * update a message
   *
   * *
   * @param params The `MessageAlertsService.MessageAlertsUpdateMessageParams` containing the following parameters:
   *
   * - `message`: FsMessageAlert data
   *
   * - `id`: the message to fetch
   *
   * - `ETag`: eTag of last update time returned from get Function
   */
  MessageAlertsUpdateMessage(params: MessageAlertsService.MessageAlertsUpdateMessageParams): __Observable<null> {
    return this.MessageAlertsUpdateMessageResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * delete a message
   *
   * *
   * @param params The `MessageAlertsService.MessageAlertsDeleteMessageParams` containing the following parameters:
   *
   * - `id`: the document to delete
   *
   * - `ETag`: eTag of last update time returned from get Function
   */
  MessageAlertsDeleteMessageResponse(params: MessageAlertsService.MessageAlertsDeleteMessageParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.ETag != null) __headers = __headers.set('ETag', params.ETag.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/message-alerts/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * delete a message
   *
   * *
   * @param params The `MessageAlertsService.MessageAlertsDeleteMessageParams` containing the following parameters:
   *
   * - `id`: the document to delete
   *
   * - `ETag`: eTag of last update time returned from get Function
   */
  MessageAlertsDeleteMessage(params: MessageAlertsService.MessageAlertsDeleteMessageParams): __Observable<null> {
    return this.MessageAlertsDeleteMessageResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * get message alerts for the app to display (non-public)
   *
   * Retrieves all message alerts currently scheduled to appear at the time of calling this endpoint, whose version number range matches
   * the specified app version and where the client list has been left unspecified (global to all clients).
   * @param version the clients app version
   * @return Ok
   */
  MessageAlertsControllerV2ListMessagesForAuthenticatedUserResponse(version: number): __Observable<__StrictHttpResponse<Array<TubAdminMessageAlertImmutable>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (version != null) __params = __params.set('version', version.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/v2/message-alerts/action/list-messages-for-user`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TubAdminMessageAlertImmutable>>;
      })
    );
  }
  /**
   * get message alerts for the app to display (non-public)
   *
   * Retrieves all message alerts currently scheduled to appear at the time of calling this endpoint, whose version number range matches
   * the specified app version and where the client list has been left unspecified (global to all clients).
   * @param version the clients app version
   * @return Ok
   */
  MessageAlertsControllerV2ListMessagesForAuthenticatedUser(version: number): __Observable<Array<TubAdminMessageAlertImmutable>> {
    return this.MessageAlertsControllerV2ListMessagesForAuthenticatedUserResponse(version).pipe(
      __map(_r => _r.body as Array<TubAdminMessageAlertImmutable>)
    );
  }

  /**
   * get message alerts for the app to display (public)
   *
   * Retrieves all message alerts currently scheduled to appear at the time of calling this endpoint, whose version number range matches
   * the specified app version and where the client list has been left unspecified (global to all clients) or includes the users
   * organisation.
   * @param version the clients app version
   * @return Ok
   */
  MessageAlertsControllerV2ListMessagesForPublicResponse(version: number): __Observable<__StrictHttpResponse<Array<TubAdminMessageAlertImmutable>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (version != null) __params = __params.set('version', version.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/v2/message-alerts/action/list-messages-for-public`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TubAdminMessageAlertImmutable>>;
      })
    );
  }
  /**
   * get message alerts for the app to display (public)
   *
   * Retrieves all message alerts currently scheduled to appear at the time of calling this endpoint, whose version number range matches
   * the specified app version and where the client list has been left unspecified (global to all clients) or includes the users
   * organisation.
   * @param version the clients app version
   * @return Ok
   */
  MessageAlertsControllerV2ListMessagesForPublic(version: number): __Observable<Array<TubAdminMessageAlertImmutable>> {
    return this.MessageAlertsControllerV2ListMessagesForPublicResponse(version).pipe(
      __map(_r => _r.body as Array<TubAdminMessageAlertImmutable>)
    );
  }

  /**
   * Retrieves all messages alerts currently defined in the system, for all versions, and all clients.
   * @return Ok
   */
  MessageAlertsControllerV2GetMessagesResponse(): __Observable<__StrictHttpResponse<Array<TubAdminMessageAlertImmutable>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/v2/message-alerts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TubAdminMessageAlertImmutable>>;
      })
    );
  }
  /**
   * Retrieves all messages alerts currently defined in the system, for all versions, and all clients.
   * @return Ok
   */
  MessageAlertsControllerV2GetMessages(): __Observable<Array<TubAdminMessageAlertImmutable>> {
    return this.MessageAlertsControllerV2GetMessagesResponse().pipe(
      __map(_r => _r.body as Array<TubAdminMessageAlertImmutable>)
    );
  }

  /**
   * create a new message alert
   *
   * *
   * @param message FsMessageAlert data
   */
  MessageAlertsControllerV2CreateMessageResponse(message: TubAdminMessageAlert): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = message;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/v2/message-alerts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * create a new message alert
   *
   * *
   * @param message FsMessageAlert data
   */
  MessageAlertsControllerV2CreateMessage(message: TubAdminMessageAlert): __Observable<null> {
    return this.MessageAlertsControllerV2CreateMessageResponse(message).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * get a message by documentRef
   *
   * *
   * @param id the message to fetch
   * @return Ok
   */
  MessageAlertsControllerV2GetMessageResponse(id: string): __Observable<__StrictHttpResponse<TubAdminMessageAlertImmutable>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/v2/message-alerts/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubAdminMessageAlertImmutable>;
      })
    );
  }
  /**
   * get a message by documentRef
   *
   * *
   * @param id the message to fetch
   * @return Ok
   */
  MessageAlertsControllerV2GetMessage(id: string): __Observable<TubAdminMessageAlertImmutable> {
    return this.MessageAlertsControllerV2GetMessageResponse(id).pipe(
      __map(_r => _r.body as TubAdminMessageAlertImmutable)
    );
  }

  /**
   * update a message
   *
   * *
   * @param params The `MessageAlertsService.MessageAlertsControllerV2UpdateMessageParams` containing the following parameters:
   *
   * - `message`: FsMessageAlert data
   *
   * - `id`: the message to fetch
   *
   * - `ETag`: eTag of last update time returned from get Function
   */
  MessageAlertsControllerV2UpdateMessageResponse(params: MessageAlertsService.MessageAlertsControllerV2UpdateMessageParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.message;

    if (params.ETag != null) __headers = __headers.set('ETag', params.ETag.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/v2/message-alerts/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * update a message
   *
   * *
   * @param params The `MessageAlertsService.MessageAlertsControllerV2UpdateMessageParams` containing the following parameters:
   *
   * - `message`: FsMessageAlert data
   *
   * - `id`: the message to fetch
   *
   * - `ETag`: eTag of last update time returned from get Function
   */
  MessageAlertsControllerV2UpdateMessage(params: MessageAlertsService.MessageAlertsControllerV2UpdateMessageParams): __Observable<null> {
    return this.MessageAlertsControllerV2UpdateMessageResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * delete a message
   *
   * *
   * @param params The `MessageAlertsService.MessageAlertsControllerV2DeleteMessageParams` containing the following parameters:
   *
   * - `id`: the document to delete
   *
   * - `ETag`: eTag of last update time returned from get Function
   */
  MessageAlertsControllerV2DeleteMessageResponse(params: MessageAlertsService.MessageAlertsControllerV2DeleteMessageParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.ETag != null) __headers = __headers.set('ETag', params.ETag.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/v2/message-alerts/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * delete a message
   *
   * *
   * @param params The `MessageAlertsService.MessageAlertsControllerV2DeleteMessageParams` containing the following parameters:
   *
   * - `id`: the document to delete
   *
   * - `ETag`: eTag of last update time returned from get Function
   */
  MessageAlertsControllerV2DeleteMessage(params: MessageAlertsService.MessageAlertsControllerV2DeleteMessageParams): __Observable<null> {
    return this.MessageAlertsControllerV2DeleteMessageResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module MessageAlertsService {

  /**
   * Parameters for MessageAlertsUpdateMessage
   */
  export interface MessageAlertsUpdateMessageParams {

    /**
     * FsMessageAlert data
     */
    message: TubAdminMessageAlert;

    /**
     * the message to fetch
     */
    id: string;

    /**
     * eTag of last update time returned from get Function
     */
    ETag: string;
  }

  /**
   * Parameters for MessageAlertsDeleteMessage
   */
  export interface MessageAlertsDeleteMessageParams {

    /**
     * the document to delete
     */
    id: string;

    /**
     * eTag of last update time returned from get Function
     */
    ETag: string;
  }

  /**
   * Parameters for MessageAlertsControllerV2UpdateMessage
   */
  export interface MessageAlertsControllerV2UpdateMessageParams {

    /**
     * FsMessageAlert data
     */
    message: TubAdminMessageAlert;

    /**
     * the message to fetch
     */
    id: string;

    /**
     * eTag of last update time returned from get Function
     */
    ETag: string;
  }

  /**
   * Parameters for MessageAlertsControllerV2DeleteMessage
   */
  export interface MessageAlertsControllerV2DeleteMessageParams {

    /**
     * the document to delete
     */
    id: string;

    /**
     * eTag of last update time returned from get Function
     */
    ETag: string;
  }
}

export { MessageAlertsService }
