/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { TubOrganisationOverview } from '../models/tub-organisation-overview';
import { TubMergedOrganisation } from '../models/tub-merged-organisation';
import { TubOrganisationDetails } from '../models/tub-organisation-details';
import { TubSubscriptionValid } from '../models/tub-subscription-valid';
import { TubOrganisationTree } from '../models/tub-organisation-tree';
import { TubOrganisationDetailsTree } from '../models/tub-organisation-details-tree';
import { TubOrganisationSettingsTree } from '../models/tub-organisation-settings-tree';
import { TubOrganisationSubscription } from '../models/tub-organisation-subscription';
import { FsOrganisationResearchSettings } from '../models/fs-organisation-research-settings';
import { TubOrganisationSettings } from '../models/tub-organisation-settings';
import { TubPolicyDocument } from '../models/tub-policy-document';
import { TubWhitelistedResponse } from '../models/tub-whitelisted-response';
import { TubOrganisationOverviewItem } from '../models/tub-organisation-overview-item';
@Injectable({
  providedIn: 'root',
})
class OrganisationsService extends __BaseService {
  static readonly OrganisationsGetOrganisationsOverviewPath = '/organisation/list';
  static readonly OrganisationsGetOrganisationForAuthenticatedPatientPath = '/organisation';
  static readonly OrganisationsCreatePath = '/organisation';
  static readonly OrganisationsGetOrganisationSubscriptionValidityForAuthenticatedPatientPath = '/organisation/subscription/valid';
  static readonly OrganisationsGetUnmergedOrganisationPath = '/organisation/{organisation}/unmerged';
  static readonly OrganisationsGetMergedOrganisationPath = '/organisation/{organisation}/merged';
  static readonly OrganisationsGetDetailsPath = '/organisation/{organisation}/details/unmerged';
  static readonly OrganisationsGetAppSettingsPath = '/organisation/{organisation}/app-settings/unmerged';
  static readonly OrganisationsGetSubscriptionPath = '/organisation/{organisation}/subscription';
  static readonly OrganisationsUpdateSubscriptionPath = '/organisation/{organisation}/subscription';
  static readonly OrganisationsGetSubscriptionUnmergedPath = '/organisation/{organisation}/subscription/unmerged';
  static readonly OrganisationsGetResearchSettingsPath = '/organisation/{organisation}/research-settings/unmerged';
  static readonly OrganisationsUpdateDetailsPath = '/organisation/{organisation}/details';
  static readonly OrganisationsUpdateAppSettingsPath = '/organisation/{organisation}/app-settings';
  static readonly OrganisationsUpdateResearchSettingsPath = '/organisation/{organisation}/research-settings';
  static readonly OrganisationsEnablePath = '/organisation/{organisation}/enable';
  static readonly OrganisationsDisablePath = '/organisation/{organisation}/disable';
  static readonly OrganisationsChangePatientsOrganisationPath = '/organisation/{organisation}/patient/{patient}';
  static readonly OrganisationsGetImageDocumentPoliciesPath = '/organisation/image-upload-policy-document';
  static readonly OrganisationsIsEmailWhitelistedPath = '/organisation/isWhitelisted';
  static readonly OrganisationsSearchPath = '/organisation/action/search';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Return a list of all available organisations
   *
   * Return a list of organisations summarised down to the basic overview data. List is returned in alphabetical order sorted on the organisation title
   * A paging key is also returned that can be passed into follow up queries to get the next
   * page of data
   * @param params The `OrganisationsService.OrganisationsGetOrganisationsOverviewParams` containing the following parameters:
   *
   * - `pageSize`: How many items to return in each page.
   *
   * - `parentOrganisation`: The document id of the parent organisation. If set, this parents children will be returned. If not set, the root organisations will be returned
   *
   * - `lastPage`: the document path of the last document searched as part of the previous query. Used as the starting point for the next query
   *
   * @return Ok
   */
  OrganisationsGetOrganisationsOverviewResponse(params: OrganisationsService.OrganisationsGetOrganisationsOverviewParams): __Observable<__StrictHttpResponse<TubOrganisationOverview>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.parentOrganisation != null) __params = __params.set('parentOrganisation', params.parentOrganisation.toString());
    if (params.lastPage != null) __params = __params.set('lastPage', params.lastPage.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organisation/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubOrganisationOverview>;
      })
    );
  }
  /**
   * Return a list of all available organisations
   *
   * Return a list of organisations summarised down to the basic overview data. List is returned in alphabetical order sorted on the organisation title
   * A paging key is also returned that can be passed into follow up queries to get the next
   * page of data
   * @param params The `OrganisationsService.OrganisationsGetOrganisationsOverviewParams` containing the following parameters:
   *
   * - `pageSize`: How many items to return in each page.
   *
   * - `parentOrganisation`: The document id of the parent organisation. If set, this parents children will be returned. If not set, the root organisations will be returned
   *
   * - `lastPage`: the document path of the last document searched as part of the previous query. Used as the starting point for the next query
   *
   * @return Ok
   */
  OrganisationsGetOrganisationsOverview(params: OrganisationsService.OrganisationsGetOrganisationsOverviewParams): __Observable<TubOrganisationOverview> {
    return this.OrganisationsGetOrganisationsOverviewResponse(params).pipe(
      __map(_r => _r.body as TubOrganisationOverview)
    );
  }

  /**
   * Get the customisation for an authenticated user.
   *
   * Get an organisation configuration for an authenticated user. It will return Thrive configurations for
   * non-authenticated users or users who do not have a subscription.
   * @return Ok
   */
  OrganisationsGetOrganisationForAuthenticatedPatientResponse(): __Observable<__StrictHttpResponse<TubMergedOrganisation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organisation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubMergedOrganisation>;
      })
    );
  }
  /**
   * Get the customisation for an authenticated user.
   *
   * Get an organisation configuration for an authenticated user. It will return Thrive configurations for
   * non-authenticated users or users who do not have a subscription.
   * @return Ok
   */
  OrganisationsGetOrganisationForAuthenticatedPatient(): __Observable<TubMergedOrganisation> {
    return this.OrganisationsGetOrganisationForAuthenticatedPatientResponse().pipe(
      __map(_r => _r.body as TubMergedOrganisation)
    );
  }

  /**
   * Create a new organisation.
   *
   * Create a new organisation, optionally settings them enabled and with max users.
   * @param params The `OrganisationsService.OrganisationsCreateParams` containing the following parameters:
   *
   * - `tubAdminOrganisationDetails`: Initial organisation's details.
   *
   * - `maxRedemptions`: Optional, maximum redemptions/users.
   *
   * @return Ok
   */
  OrganisationsCreateResponse(params: OrganisationsService.OrganisationsCreateParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.tubAdminOrganisationDetails;
    if (params.maxRedemptions != null) __params = __params.set('maxRedemptions', params.maxRedemptions.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/organisation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * Create a new organisation.
   *
   * Create a new organisation, optionally settings them enabled and with max users.
   * @param params The `OrganisationsService.OrganisationsCreateParams` containing the following parameters:
   *
   * - `tubAdminOrganisationDetails`: Initial organisation's details.
   *
   * - `maxRedemptions`: Optional, maximum redemptions/users.
   *
   * @return Ok
   */
  OrganisationsCreate(params: OrganisationsService.OrganisationsCreateParams): __Observable<string> {
    return this.OrganisationsCreateResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * Get an authenticated user's organisation's subscription validity.
   * @return Ok
   */
  OrganisationsGetOrganisationSubscriptionValidityForAuthenticatedPatientResponse(): __Observable<__StrictHttpResponse<TubSubscriptionValid>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organisation/subscription/valid`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubSubscriptionValid>;
      })
    );
  }
  /**
   * Get an authenticated user's organisation's subscription validity.
   * @return Ok
   */
  OrganisationsGetOrganisationSubscriptionValidityForAuthenticatedPatient(): __Observable<TubSubscriptionValid> {
    return this.OrganisationsGetOrganisationSubscriptionValidityForAuthenticatedPatientResponse().pipe(
      __map(_r => _r.body as TubSubscriptionValid)
    );
  }

  /**
   * Get an organisation's unmerged config using their firestore ID.
   *
   * Get an organisation's unmerged config using their firestore ID. The returned {@link TubOrganisationTree} contains
   * the requested organisation configuration and its parent hierarchy.
   *
   * NOTE: Calling this method sets the response header ETag to the organisation's last update time. Calling functions need not set this again.
   * @param organisation Organisation's firestore ID.
   * @return Ok
   */
  OrganisationsGetUnmergedOrganisationResponse(organisation: string): __Observable<__StrictHttpResponse<TubOrganisationTree>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organisation/${encodeURIComponent(String(organisation))}/unmerged`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubOrganisationTree>;
      })
    );
  }
  /**
   * Get an organisation's unmerged config using their firestore ID.
   *
   * Get an organisation's unmerged config using their firestore ID. The returned {@link TubOrganisationTree} contains
   * the requested organisation configuration and its parent hierarchy.
   *
   * NOTE: Calling this method sets the response header ETag to the organisation's last update time. Calling functions need not set this again.
   * @param organisation Organisation's firestore ID.
   * @return Ok
   */
  OrganisationsGetUnmergedOrganisation(organisation: string): __Observable<TubOrganisationTree> {
    return this.OrganisationsGetUnmergedOrganisationResponse(organisation).pipe(
      __map(_r => _r.body as TubOrganisationTree)
    );
  }

  /**
   * Get an organisation's merged config using their firestore ID.
   * @param organisation Organisation's firestore ID.
   * @return Ok
   */
  OrganisationsGetMergedOrganisationResponse(organisation: string): __Observable<__StrictHttpResponse<TubMergedOrganisation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organisation/${encodeURIComponent(String(organisation))}/merged`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubMergedOrganisation>;
      })
    );
  }
  /**
   * Get an organisation's merged config using their firestore ID.
   * @param organisation Organisation's firestore ID.
   * @return Ok
   */
  OrganisationsGetMergedOrganisation(organisation: string): __Observable<TubMergedOrganisation> {
    return this.OrganisationsGetMergedOrganisationResponse(organisation).pipe(
      __map(_r => _r.body as TubMergedOrganisation)
    );
  }

  /**
   * @param organisation
   * @return Ok
   */
  OrganisationsGetDetailsResponse(organisation: string): __Observable<__StrictHttpResponse<TubOrganisationDetailsTree>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organisation/${encodeURIComponent(String(organisation))}/details/unmerged`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubOrganisationDetailsTree>;
      })
    );
  }
  /**
   * @param organisation
   * @return Ok
   */
  OrganisationsGetDetails(organisation: string): __Observable<TubOrganisationDetailsTree> {
    return this.OrganisationsGetDetailsResponse(organisation).pipe(
      __map(_r => _r.body as TubOrganisationDetailsTree)
    );
  }

  /**
   * @param organisation
   * @return Ok
   */
  OrganisationsGetAppSettingsResponse(organisation: string): __Observable<__StrictHttpResponse<TubOrganisationSettingsTree>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organisation/${encodeURIComponent(String(organisation))}/app-settings/unmerged`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubOrganisationSettingsTree>;
      })
    );
  }
  /**
   * @param organisation
   * @return Ok
   */
  OrganisationsGetAppSettings(organisation: string): __Observable<TubOrganisationSettingsTree> {
    return this.OrganisationsGetAppSettingsResponse(organisation).pipe(
      __map(_r => _r.body as TubOrganisationSettingsTree)
    );
  }

  /**
   * @param organisation
   * @return Ok
   */
  OrganisationsGetSubscriptionResponse(organisation: string): __Observable<__StrictHttpResponse<TubOrganisationSubscription>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organisation/${encodeURIComponent(String(organisation))}/subscription`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubOrganisationSubscription>;
      })
    );
  }
  /**
   * @param organisation
   * @return Ok
   */
  OrganisationsGetSubscription(organisation: string): __Observable<TubOrganisationSubscription> {
    return this.OrganisationsGetSubscriptionResponse(organisation).pipe(
      __map(_r => _r.body as TubOrganisationSubscription)
    );
  }

  /**
   * Update an organisations subscription.
   * @param params The `OrganisationsService.OrganisationsUpdateSubscriptionParams` containing the following parameters:
   *
   * - `subscription`: New subscription data.
   *
   * - `organisation`: Organisation firestore ID.
   *
   * - `ETag`: eTag of last update time returned from get function
   */
  OrganisationsUpdateSubscriptionResponse(params: OrganisationsService.OrganisationsUpdateSubscriptionParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.subscription;

    if (params.ETag != null) __headers = __headers.set('ETag', params.ETag.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/organisation/${encodeURIComponent(String(params.organisation))}/subscription`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Update an organisations subscription.
   * @param params The `OrganisationsService.OrganisationsUpdateSubscriptionParams` containing the following parameters:
   *
   * - `subscription`: New subscription data.
   *
   * - `organisation`: Organisation firestore ID.
   *
   * - `ETag`: eTag of last update time returned from get function
   */
  OrganisationsUpdateSubscription(params: OrganisationsService.OrganisationsUpdateSubscriptionParams): __Observable<null> {
    return this.OrganisationsUpdateSubscriptionResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param organisation
   * @return Ok
   */
  OrganisationsGetSubscriptionUnmergedResponse(organisation: string): __Observable<__StrictHttpResponse<TubOrganisationSubscription>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organisation/${encodeURIComponent(String(organisation))}/subscription/unmerged`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubOrganisationSubscription>;
      })
    );
  }
  /**
   * @param organisation
   * @return Ok
   */
  OrganisationsGetSubscriptionUnmerged(organisation: string): __Observable<TubOrganisationSubscription> {
    return this.OrganisationsGetSubscriptionUnmergedResponse(organisation).pipe(
      __map(_r => _r.body as TubOrganisationSubscription)
    );
  }

  /**
   * @param organisation
   * @return Ok
   */
  OrganisationsGetResearchSettingsResponse(organisation: string): __Observable<__StrictHttpResponse<FsOrganisationResearchSettings>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organisation/${encodeURIComponent(String(organisation))}/research-settings/unmerged`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FsOrganisationResearchSettings>;
      })
    );
  }
  /**
   * @param organisation
   * @return Ok
   */
  OrganisationsGetResearchSettings(organisation: string): __Observable<FsOrganisationResearchSettings> {
    return this.OrganisationsGetResearchSettingsResponse(organisation).pipe(
      __map(_r => _r.body as FsOrganisationResearchSettings)
    );
  }

  /**
   * Update an organisation's details.
   * @param params The `OrganisationsService.OrganisationsUpdateDetailsParams` containing the following parameters:
   *
   * - `organisationDetails`: New organisation details.
   *
   * - `organisation`: Organisation firestore ID.
   *
   * - `ETag`: eTag of last update time returned from get function
   */
  OrganisationsUpdateDetailsResponse(params: OrganisationsService.OrganisationsUpdateDetailsParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.organisationDetails;

    if (params.ETag != null) __headers = __headers.set('ETag', params.ETag.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/organisation/${encodeURIComponent(String(params.organisation))}/details`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Update an organisation's details.
   * @param params The `OrganisationsService.OrganisationsUpdateDetailsParams` containing the following parameters:
   *
   * - `organisationDetails`: New organisation details.
   *
   * - `organisation`: Organisation firestore ID.
   *
   * - `ETag`: eTag of last update time returned from get function
   */
  OrganisationsUpdateDetails(params: OrganisationsService.OrganisationsUpdateDetailsParams): __Observable<null> {
    return this.OrganisationsUpdateDetailsResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Update an organisation's app settings.
   * @param params The `OrganisationsService.OrganisationsUpdateAppSettingsParams` containing the following parameters:
   *
   * - `organisationAppSettings`: New organisation app settings.
   *
   * - `organisation`: Organisation firestore ID.
   *
   * - `ETag`: eTag of last update time returned from get function
   */
  OrganisationsUpdateAppSettingsResponse(params: OrganisationsService.OrganisationsUpdateAppSettingsParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.organisationAppSettings;

    if (params.ETag != null) __headers = __headers.set('ETag', params.ETag.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/organisation/${encodeURIComponent(String(params.organisation))}/app-settings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Update an organisation's app settings.
   * @param params The `OrganisationsService.OrganisationsUpdateAppSettingsParams` containing the following parameters:
   *
   * - `organisationAppSettings`: New organisation app settings.
   *
   * - `organisation`: Organisation firestore ID.
   *
   * - `ETag`: eTag of last update time returned from get function
   */
  OrganisationsUpdateAppSettings(params: OrganisationsService.OrganisationsUpdateAppSettingsParams): __Observable<null> {
    return this.OrganisationsUpdateAppSettingsResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Update research settings
   * @param params The `OrganisationsService.OrganisationsUpdateResearchSettingsParams` containing the following parameters:
   *
   * - `researchSettings`: New research settings data
   *
   * - `organisation`: Organisation firestore ID.
   *
   * - `ETag`: eTag of last update time returned from get function
   */
  OrganisationsUpdateResearchSettingsResponse(params: OrganisationsService.OrganisationsUpdateResearchSettingsParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.researchSettings;

    if (params.ETag != null) __headers = __headers.set('ETag', params.ETag.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/organisation/${encodeURIComponent(String(params.organisation))}/research-settings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Update research settings
   * @param params The `OrganisationsService.OrganisationsUpdateResearchSettingsParams` containing the following parameters:
   *
   * - `researchSettings`: New research settings data
   *
   * - `organisation`: Organisation firestore ID.
   *
   * - `ETag`: eTag of last update time returned from get function
   */
  OrganisationsUpdateResearchSettings(params: OrganisationsService.OrganisationsUpdateResearchSettingsParams): __Observable<null> {
    return this.OrganisationsUpdateResearchSettingsResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Disable an organisation's subscription.
   * @param params The `OrganisationsService.OrganisationsEnableParams` containing the following parameters:
   *
   * - `organisation`: Organisation firestore ID.
   *
   * - `maxRedemptions`: Optional new max redemptions value.
   */
  OrganisationsEnableResponse(params: OrganisationsService.OrganisationsEnableParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.maxRedemptions != null) __params = __params.set('maxRedemptions', params.maxRedemptions.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/organisation/${encodeURIComponent(String(params.organisation))}/enable`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Disable an organisation's subscription.
   * @param params The `OrganisationsService.OrganisationsEnableParams` containing the following parameters:
   *
   * - `organisation`: Organisation firestore ID.
   *
   * - `maxRedemptions`: Optional new max redemptions value.
   */
  OrganisationsEnable(params: OrganisationsService.OrganisationsEnableParams): __Observable<null> {
    return this.OrganisationsEnableResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Disable an organisation's subscription.
   * @param organisation Organisation firestore ID.
   */
  OrganisationsDisableResponse(organisation: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/organisation/${encodeURIComponent(String(organisation))}/disable`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Disable an organisation's subscription.
   * @param organisation Organisation firestore ID.
   */
  OrganisationsDisable(organisation: string): __Observable<null> {
    return this.OrganisationsDisableResponse(organisation).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Move a patient to a new organisation.
   * @param params The `OrganisationsService.OrganisationsChangePatientsOrganisationParams` containing the following parameters:
   *
   * - `patient`: Patient firestore ID
   *
   * - `organisation`: Organisation firestore ID
   */
  OrganisationsChangePatientsOrganisationResponse(params: OrganisationsService.OrganisationsChangePatientsOrganisationParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/organisation/${encodeURIComponent(String(params.organisation))}/patient/${encodeURIComponent(String(params.patient))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Move a patient to a new organisation.
   * @param params The `OrganisationsService.OrganisationsChangePatientsOrganisationParams` containing the following parameters:
   *
   * - `patient`: Patient firestore ID
   *
   * - `organisation`: Organisation firestore ID
   */
  OrganisationsChangePatientsOrganisation(params: OrganisationsService.OrganisationsChangePatientsOrganisationParams): __Observable<null> {
    return this.OrganisationsChangePatientsOrganisationResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get a policy document to upload an image to the organisation images bucket.
   * @return Ok
   */
  OrganisationsGetImageDocumentPoliciesResponse(): __Observable<__StrictHttpResponse<TubPolicyDocument>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organisation/image-upload-policy-document`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubPolicyDocument>;
      })
    );
  }
  /**
   * Get a policy document to upload an image to the organisation images bucket.
   * @return Ok
   */
  OrganisationsGetImageDocumentPolicies(): __Observable<TubPolicyDocument> {
    return this.OrganisationsGetImageDocumentPoliciesResponse().pipe(
      __map(_r => _r.body as TubPolicyDocument)
    );
  }

  /**
   * Check if a provided email is whitelisted by an organisation
   *
   * Check if a provided email is whitelisted by an organisation
   * @param email The email to check
   * @return Ok
   */
  OrganisationsIsEmailWhitelistedResponse(email: string): __Observable<__StrictHttpResponse<TubWhitelistedResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (email != null) __params = __params.set('email', email.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organisation/isWhitelisted`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubWhitelistedResponse>;
      })
    );
  }
  /**
   * Check if a provided email is whitelisted by an organisation
   *
   * Check if a provided email is whitelisted by an organisation
   * @param email The email to check
   * @return Ok
   */
  OrganisationsIsEmailWhitelisted(email: string): __Observable<TubWhitelistedResponse> {
    return this.OrganisationsIsEmailWhitelistedResponse(email).pipe(
      __map(_r => _r.body as TubWhitelistedResponse)
    );
  }

  /**
   * Finds an organisation where the name, title, description or one of the access codes contains the search string.
   * @param term The search term
   * @return Ok
   */
  OrganisationsSearchResponse(term: string): __Observable<__StrictHttpResponse<Array<TubOrganisationOverviewItem>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (term != null) __params = __params.set('term', term.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organisation/action/search`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TubOrganisationOverviewItem>>;
      })
    );
  }
  /**
   * Finds an organisation where the name, title, description or one of the access codes contains the search string.
   * @param term The search term
   * @return Ok
   */
  OrganisationsSearch(term: string): __Observable<Array<TubOrganisationOverviewItem>> {
    return this.OrganisationsSearchResponse(term).pipe(
      __map(_r => _r.body as Array<TubOrganisationOverviewItem>)
    );
  }
}

module OrganisationsService {

  /**
   * Parameters for OrganisationsGetOrganisationsOverview
   */
  export interface OrganisationsGetOrganisationsOverviewParams {

    /**
     * How many items to return in each page.
     */
    pageSize: number;

    /**
     * The document id of the parent organisation. If set, this parents children will be returned. If not set, the root organisations will be returned
     */
    parentOrganisation?: string;

    /**
     * the document path of the last document searched as part of the previous query. Used as the starting point for the next query
     */
    lastPage?: string;
  }

  /**
   * Parameters for OrganisationsCreate
   */
  export interface OrganisationsCreateParams {

    /**
     * Initial organisation's details.
     */
    tubAdminOrganisationDetails: TubOrganisationDetails;

    /**
     * Optional, maximum redemptions/users.
     */
    maxRedemptions?: number;
  }

  /**
   * Parameters for OrganisationsUpdateSubscription
   */
  export interface OrganisationsUpdateSubscriptionParams {

    /**
     * New subscription data.
     */
    subscription: TubOrganisationSubscription;

    /**
     * Organisation firestore ID.
     */
    organisation: string;

    /**
     * eTag of last update time returned from get function
     */
    ETag: string;
  }

  /**
   * Parameters for OrganisationsUpdateDetails
   */
  export interface OrganisationsUpdateDetailsParams {

    /**
     * New organisation details.
     */
    organisationDetails: TubOrganisationDetails;

    /**
     * Organisation firestore ID.
     */
    organisation: string;

    /**
     * eTag of last update time returned from get function
     */
    ETag: string;
  }

  /**
   * Parameters for OrganisationsUpdateAppSettings
   */
  export interface OrganisationsUpdateAppSettingsParams {

    /**
     * New organisation app settings.
     */
    organisationAppSettings: TubOrganisationSettings;

    /**
     * Organisation firestore ID.
     */
    organisation: string;

    /**
     * eTag of last update time returned from get function
     */
    ETag: string;
  }

  /**
   * Parameters for OrganisationsUpdateResearchSettings
   */
  export interface OrganisationsUpdateResearchSettingsParams {

    /**
     * New research settings data
     */
    researchSettings: FsOrganisationResearchSettings;

    /**
     * Organisation firestore ID.
     */
    organisation: string;

    /**
     * eTag of last update time returned from get function
     */
    ETag: string;
  }

  /**
   * Parameters for OrganisationsEnable
   */
  export interface OrganisationsEnableParams {

    /**
     * Organisation firestore ID.
     */
    organisation: string;

    /**
     * Optional new max redemptions value.
     */
    maxRedemptions?: number;
  }

  /**
   * Parameters for OrganisationsChangePatientsOrganisation
   */
  export interface OrganisationsChangePatientsOrganisationParams {

    /**
     * Patient firestore ID
     */
    patient: string;

    /**
     * Organisation firestore ID
     */
    organisation: string;
  }
}

export { OrganisationsService }
