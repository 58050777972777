import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

import { take, withLatestFrom } from 'rxjs/operators';
import { IAlbum, Lightbox } from 'ngx-lightbox';
import { TranslateService } from '@ngx-translate/core';

import { GuideConfiguration } from './guide-configuration';
import { GuideAlbumService } from './guide-album.service';
import { GuideConfigService } from './guide-config.service';

@Component({
  selector: 'app-openid-login-guide',
  templateUrl: './openid-login-guide.component.html',
  styleUrls: [ './openid-login-guide.component.scss' ]
})
export class OpenidLoginGuideComponent implements OnInit {

  public albums: Array<IAlbum> = [];
  public clientID: string;
  public clientLogo: string;
  public ready: boolean;
  public error: HttpErrorResponse;
  public config: GuideConfiguration;
  public introduction: string;

  private platform: string;

  constructor(private activatedRoute: ActivatedRoute,
              private translateService: TranslateService,
              private lightbox: Lightbox,
              private guideConfigService: GuideConfigService,
              private guideAlbumService: GuideAlbumService,
              private router: Router) {

  }

  public ngOnInit(): void {
    this.activatedRoute.url.pipe(withLatestFrom(this.activatedRoute.paramMap, this.activatedRoute.queryParamMap))
      .subscribe(([ , paramMap, queryParamMap ]) => {
        const language = queryParamMap.get('lang') ?? 'en';
        this.translateService.use(language).pipe(take(1)).subscribe(() => {
          console.log(`Successfully set '${language}' language.'`);
        }, () => {
          console.error(`Problem with '${language}' language initialization.'`);
        }, async () => {
          this.platform = paramMap.get('platform') ?? 'Android';
          this.clientID = paramMap.get('client');
          this.clientLogo = `assets/guide/${this.clientID}/logo.png`;

          await this.guideConfigService.getConfig(this.clientID)
            .catch(err => this.error = err)
            .then(async (data: GuideConfiguration) => {
              if (data && !this.error) {
                this.config = data;
                this.introduction = await this.getDescription();
                this.albums = await this.guideAlbumService.getAlbum(this.platform, this.clientID);
                this.ready = true;
              } else if (this.error) {
                this.router.navigate(['404']);
              }
            });
        });
      });
  }

  public open(index: number): void {
    // open lightbox
    this.lightbox.open(this.albums, index);
  }

  public openAppStore(): void {
    window.location.href = 'https://deeplink.thrive.uk.com/app';
  }

  private async getDescription(): Promise<string> {
    const param = {
      storeName: this.platform === 'ios' ? this.translateService.instant('AppStore') : this.translateService.instant('PlayStore'),
      serviceName: this.config.serviceName
    };
    return await this.translateService.get('InstallGuideIntro', param).toPromise();
  }
}
