/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { TubQueryStringToken } from '../models/tub-query-string-token';
@Injectable({
  providedIn: 'root',
})
class QueryStringTokenService extends __BaseService {
  static readonly QueryStringTokenCreateTokenPath = '/iams/query-string-token/create';
  static readonly QueryStringTokenGetAndUseTokenPath = '/iams/query-string-token/use';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * creates a query string token
   *
   * Create a token document and return its ID.
   * @return Ok
   */
  QueryStringTokenCreateTokenResponse(): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/iams/query-string-token/create`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * creates a query string token
   *
   * Create a token document and return its ID.
   * @return Ok
   */
  QueryStringTokenCreateToken(): __Observable<string> {
    return this.QueryStringTokenCreateTokenResponse().pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * returns a token and removes it from the database
   *
   * Get a token document and return it. This will destroy the token
   * @param tokenID
   * @return Ok
   */
  QueryStringTokenGetAndUseTokenResponse(tokenID: string): __Observable<__StrictHttpResponse<TubQueryStringToken>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (tokenID != null) __params = __params.set('tokenID', tokenID.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/iams/query-string-token/use`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubQueryStringToken>;
      })
    );
  }
  /**
   * returns a token and removes it from the database
   *
   * Get a token document and return it. This will destroy the token
   * @param tokenID
   * @return Ok
   */
  QueryStringTokenGetAndUseToken(tokenID: string): __Observable<TubQueryStringToken> {
    return this.QueryStringTokenGetAndUseTokenResponse(tokenID).pipe(
      __map(_r => _r.body as TubQueryStringToken)
    );
  }
}

module QueryStringTokenService {
}

export { QueryStringTokenService }
