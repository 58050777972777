/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ScopeDetailsResponse } from '../models/scope-details-response';
import { ScopesListResponse } from '../models/scopes-list-response';
@Injectable({
  providedIn: 'root',
})
class IAMSScopesInfoService extends __BaseService {
  static readonly IamsScopesInfoGetScopesPath = '/iams/scopes/all';
  static readonly IamsScopesInfoGetCurrentlyActiveScopesPath = '/iams/scopes/current';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * list all possible security scopes
   *
   * List all possible security scopes defined in the system across all endpoint controllers, along with descriptive
   * information useful to end clients.
   * @return Ok
   */
  IamsScopesInfoGetScopesResponse(): __Observable<__StrictHttpResponse<ScopeDetailsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/iams/scopes/all`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ScopeDetailsResponse>;
      })
    );
  }
  /**
   * list all possible security scopes
   *
   * List all possible security scopes defined in the system across all endpoint controllers, along with descriptive
   * information useful to end clients.
   * @return Ok
   */
  IamsScopesInfoGetScopes(): __Observable<ScopeDetailsResponse> {
    return this.IamsScopesInfoGetScopesResponse().pipe(
      __map(_r => _r.body as ScopeDetailsResponse)
    );
  }

  /**
   * get list of scopes for active session
   *
   * Retrieve a list of all the scopes available to the currently active session
   * @return Ok
   */
  IamsScopesInfoGetCurrentlyActiveScopesResponse(): __Observable<__StrictHttpResponse<ScopesListResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/iams/scopes/current`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ScopesListResponse>;
      })
    );
  }
  /**
   * get list of scopes for active session
   *
   * Retrieve a list of all the scopes available to the currently active session
   * @return Ok
   */
  IamsScopesInfoGetCurrentlyActiveScopes(): __Observable<ScopesListResponse> {
    return this.IamsScopesInfoGetCurrentlyActiveScopesResponse().pipe(
      __map(_r => _r.body as ScopesListResponse)
    );
  }
}

module IAMSScopesInfoService {
}

export { IAMSScopesInfoService }
