import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';

import { SharedModule } from '../shared/shared.module';
import { OpenidLandingComponent } from './openid-landing/openid-landing.component';
import { OpenIDRoutingModule } from './openid-routing.module';
import { CanGoBackPipe } from './openid-landing/can-go-back.pipe';
import { OpenidLoginGuideComponent } from './openid-login-guide/openid-login-guide.component';

@NgModule({
  declarations: [
    OpenidLandingComponent,
    CanGoBackPipe,
    OpenidLoginGuideComponent
  ],
  imports: [
    CommonModule,
    OpenIDRoutingModule,
    SharedModule,
    TranslateModule,
  ]
})
export class OpenIDModule { }
