import { Injectable } from '@angular/core';

import { throttleTime } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { ErrorReportingService } from '../../../../backend-client/services';
declare global {
  interface Window {
    logBuffer: Array<any>;
  }
}

export const getCircularReplacer = () => {
  const seen = new WeakSet();
  return (_, value) => {
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) {
        return;
      }
      seen.add(value);
    }
    return value;
  };
};

@Injectable({
  providedIn: 'root'
})
export class TubErrorReportingService {

  private errors = new Subject();

  constructor(private errorReportingService: ErrorReportingService) {
    this.errors.pipe(throttleTime(1000)).subscribe(error => {
      this.errorReportingService.ErrorReportingReport(error).toPromise();
    });
  }

  public send(message: string, error?: Error, context?: any): void {
    const errorDetails = {
      message,
      reporter: 'THRIVE_AUTH_REPORTER',
      stack: error?.stack,
      context,
      logBuffer: JSON.parse(JSON.stringify(window?.logBuffer ?? [], getCircularReplacer()))
    };
    this.errors.next(errorDetails);
  }
}