/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { TubTherapistDetails } from '../models/tub-therapist-details';
import { TubTimeZone } from '../models/tub-time-zone';
@Injectable({
  providedIn: 'root',
})
class TherapistsService extends __BaseService {
  static readonly TherapistsGetTherapistDetailsPath = '/therapists/details/{therapist}';
  static readonly TherapistsGetTimezonesPath = '/therapists/timezones';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get the details for a specific therapist.
   * @param therapist
   * @return Ok
   */
  TherapistsGetTherapistDetailsResponse(therapist: string): __Observable<__StrictHttpResponse<TubTherapistDetails>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/therapists/details/${encodeURIComponent(String(therapist))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubTherapistDetails>;
      })
    );
  }
  /**
   * Get the details for a specific therapist.
   * @param therapist
   * @return Ok
   */
  TherapistsGetTherapistDetails(therapist: string): __Observable<TubTherapistDetails> {
    return this.TherapistsGetTherapistDetailsResponse(therapist).pipe(
      __map(_r => _r.body as TubTherapistDetails)
    );
  }

  /**
   * Returns a list of valid timezones
   * @return Ok
   */
  TherapistsGetTimezonesResponse(): __Observable<__StrictHttpResponse<Array<TubTimeZone>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/therapists/timezones`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TubTimeZone>>;
      })
    );
  }
  /**
   * Returns a list of valid timezones
   * @return Ok
   */
  TherapistsGetTimezones(): __Observable<Array<TubTimeZone>> {
    return this.TherapistsGetTimezonesResponse().pipe(
      __map(_r => _r.body as Array<TubTimeZone>)
    );
  }
}

module TherapistsService {
}

export { TherapistsService }
