/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { TubPatient } from '../models/tub-patient';
import { TubPatientGraph } from '../models/tub-patient-graph';
import { TubTherapistDetails } from '../models/tub-therapist-details';
@Injectable({
  providedIn: 'root',
})
class PatientsService extends __BaseService {
  static readonly PatientsGetPatientPath = '/patients/{patientId}';
  static readonly PatientsGetPatientGraphDataPath = '/patients/{patientId}/graph';
  static readonly PatientsGetEligibleTherapistsPath = '/patients/{patientId}/eligible-therapists';
  static readonly PatientsSharePatientDataWithTherapistPath = '/patients/{patientId}/share-data/{therapistId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * get a patients basic details
   *
   * Retrieve details about a given patient.  Must have access to the patient in order to make the request.
   * @param patientId the patientId of the patient for which to retrieve information
   * @return Ok
   */
  PatientsGetPatientResponse(patientId: string): __Observable<__StrictHttpResponse<TubPatient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/patients/${encodeURIComponent(String(patientId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubPatient>;
      })
    );
  }
  /**
   * get a patients basic details
   *
   * Retrieve details about a given patient.  Must have access to the patient in order to make the request.
   * @param patientId the patientId of the patient for which to retrieve information
   * @return Ok
   */
  PatientsGetPatient(patientId: string): __Observable<TubPatient> {
    return this.PatientsGetPatientResponse(patientId).pipe(
      __map(_r => _r.body as TubPatient)
    );
  }

  /**
   * get graph data for a specific user
   *
   * Get the GAD and PHQ scores, with a timestamp of `startFrom` or earlier and 'endAt' or later, for the specified user.
   * @param params The `PatientsService.PatientsGetPatientGraphDataParams` containing the following parameters:
   *
   * - `startFrom`: The timestamp at which to start retrieving moments from the patient record.
   *
   * - `patientId`: the id for the patient whose data we are requesting
   *
   * - `endAt`: Limit the query to a specific time period. Note that `endAt` must be a time earlier than `startFrom`.
   *
   * @return Ok
   */
  PatientsGetPatientGraphDataResponse(params: PatientsService.PatientsGetPatientGraphDataParams): __Observable<__StrictHttpResponse<TubPatientGraph>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startFrom != null) __params = __params.set('startFrom', params.startFrom.toString());

    if (params.endAt != null) __params = __params.set('endAt', params.endAt.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/patients/${encodeURIComponent(String(params.patientId))}/graph`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubPatientGraph>;
      })
    );
  }
  /**
   * get graph data for a specific user
   *
   * Get the GAD and PHQ scores, with a timestamp of `startFrom` or earlier and 'endAt' or later, for the specified user.
   * @param params The `PatientsService.PatientsGetPatientGraphDataParams` containing the following parameters:
   *
   * - `startFrom`: The timestamp at which to start retrieving moments from the patient record.
   *
   * - `patientId`: the id for the patient whose data we are requesting
   *
   * - `endAt`: Limit the query to a specific time period. Note that `endAt` must be a time earlier than `startFrom`.
   *
   * @return Ok
   */
  PatientsGetPatientGraphData(params: PatientsService.PatientsGetPatientGraphDataParams): __Observable<TubPatientGraph> {
    return this.PatientsGetPatientGraphDataResponse(params).pipe(
      __map(_r => _r.body as TubPatientGraph)
    );
  }

  /**
   * discover therapists who can interact with a given patient
   *
   * Retrieve a list of all therapists who would be eligible to interact with this patient, if they were invited.
   * In order to discover other eligible therapists, the authenticated therapist must have data access to this patient.
   * This is useful for providing lists of therapists during invite or handover operations.
   * @param params The `PatientsService.PatientsGetEligibleTherapistsParams` containing the following parameters:
   *
   * - `patientId`: ID of the patient for whom to discover eligible therapists
   *
   * - `purpose`: optional purposes flags to filter the eligibility check, defaults to blank (no filter)
   *
   * @return Ok
   */
  PatientsGetEligibleTherapistsResponse(params: PatientsService.PatientsGetEligibleTherapistsParams): __Observable<__StrictHttpResponse<Array<TubTherapistDetails>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    (params.purpose || []).forEach(val => {if (val != null) __params = __params.append('purpose', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/patients/${encodeURIComponent(String(params.patientId))}/eligible-therapists`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TubTherapistDetails>>;
      })
    );
  }
  /**
   * discover therapists who can interact with a given patient
   *
   * Retrieve a list of all therapists who would be eligible to interact with this patient, if they were invited.
   * In order to discover other eligible therapists, the authenticated therapist must have data access to this patient.
   * This is useful for providing lists of therapists during invite or handover operations.
   * @param params The `PatientsService.PatientsGetEligibleTherapistsParams` containing the following parameters:
   *
   * - `patientId`: ID of the patient for whom to discover eligible therapists
   *
   * - `purpose`: optional purposes flags to filter the eligibility check, defaults to blank (no filter)
   *
   * @return Ok
   */
  PatientsGetEligibleTherapists(params: PatientsService.PatientsGetEligibleTherapistsParams): __Observable<Array<TubTherapistDetails>> {
    return this.PatientsGetEligibleTherapistsResponse(params).pipe(
      __map(_r => _r.body as Array<TubTherapistDetails>)
    );
  }

  /**
   * share a patients data with another therapist
   *
   * As an authenticated therapist, share the specified patients' data with another specified therapist.
   * In order to share patient data with another therapist user, the user must already have access to the patient's data,
   * and the target therapist must be eligible (as determined by organisation membership).
   * @param params The `PatientsService.PatientsSharePatientDataWithTherapistParams` containing the following parameters:
   *
   * - `therapistId`: identifies the therapist with whom the data will be shared
   *
   * - `patientId`: identifies the patient for whom to share data
   */
  PatientsSharePatientDataWithTherapistResponse(params: PatientsService.PatientsSharePatientDataWithTherapistParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/patients/${encodeURIComponent(String(params.patientId))}/share-data/${encodeURIComponent(String(params.therapistId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * share a patients data with another therapist
   *
   * As an authenticated therapist, share the specified patients' data with another specified therapist.
   * In order to share patient data with another therapist user, the user must already have access to the patient's data,
   * and the target therapist must be eligible (as determined by organisation membership).
   * @param params The `PatientsService.PatientsSharePatientDataWithTherapistParams` containing the following parameters:
   *
   * - `therapistId`: identifies the therapist with whom the data will be shared
   *
   * - `patientId`: identifies the patient for whom to share data
   */
  PatientsSharePatientDataWithTherapist(params: PatientsService.PatientsSharePatientDataWithTherapistParams): __Observable<null> {
    return this.PatientsSharePatientDataWithTherapistResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module PatientsService {

  /**
   * Parameters for PatientsGetPatientGraphData
   */
  export interface PatientsGetPatientGraphDataParams {

    /**
     * The timestamp at which to start retrieving moments from the patient record.
     */
    startFrom: number;

    /**
     * the id for the patient whose data we are requesting
     */
    patientId: string;

    /**
     * Limit the query to a specific time period. Note that `endAt` must be a time earlier than `startFrom`.
     */
    endAt?: number;
  }

  /**
   * Parameters for PatientsGetEligibleTherapists
   */
  export interface PatientsGetEligibleTherapistsParams {

    /**
     * ID of the patient for whom to discover eligible therapists
     */
    patientId: string;

    /**
     * optional purposes flags to filter the eligibility check, defaults to blank (no filter)
     */
    purpose?: Array<'lead' | 'chat' | 'access'>;
  }

  /**
   * Parameters for PatientsSharePatientDataWithTherapist
   */
  export interface PatientsSharePatientDataWithTherapistParams {

    /**
     * identifies the therapist with whom the data will be shared
     */
    therapistId: string;

    /**
     * identifies the patient for whom to share data
     */
    patientId: string;
  }
}

export { PatientsService }
