/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { TubExportResults } from '../models/tub-export-results';
import { TubExportParameters } from '../models/tub-export-parameters';
import { TubCopyResult } from '../models/tub-copy-result';
@Injectable({
  providedIn: 'root',
})
class UsersDataService extends __BaseService {
  static readonly UsersDataExportUsersDataPath = '/users/data/action/clone-user/{userId}';
  static readonly UsersDataExportOnlyUsersDataPath = '/users/data/{userId}/export';
  static readonly UsersDataImportUsersDataPath = '/users/data';
  static readonly UsersDataDeleteUsersDataPath = '/users/data';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Export the given user's data to a new environment.
   * @param params The `UsersDataService.UsersDataExportUsersDataParams` containing the following parameters:
   *
   * - `userId`: Firestore UID of user document or account email.
   *
   * - `obfuscated`: States whether the export should be obfuscated.
   *
   * - `exportParameters`: parameters for the export, please see the TubExportParameters model below for details
   *
   * - `destination`: thrive-unified-backend, thrive-unified-backend-stage, or thrive-unified-backend-dev.
   *
   * @return Ok
   */
  UsersDataExportUsersDataResponse(params: UsersDataService.UsersDataExportUsersDataParams): __Observable<__StrictHttpResponse<TubExportResults>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.obfuscated != null) __params = __params.set('obfuscated', params.obfuscated.toString());
    __body = params.exportParameters;
    if (params.destination != null) __params = __params.set('destination', params.destination.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/users/data/action/clone-user/${encodeURIComponent(String(params.userId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubExportResults>;
      })
    );
  }
  /**
   * Export the given user's data to a new environment.
   * @param params The `UsersDataService.UsersDataExportUsersDataParams` containing the following parameters:
   *
   * - `userId`: Firestore UID of user document or account email.
   *
   * - `obfuscated`: States whether the export should be obfuscated.
   *
   * - `exportParameters`: parameters for the export, please see the TubExportParameters model below for details
   *
   * - `destination`: thrive-unified-backend, thrive-unified-backend-stage, or thrive-unified-backend-dev.
   *
   * @return Ok
   */
  UsersDataExportUsersData(params: UsersDataService.UsersDataExportUsersDataParams): __Observable<TubExportResults> {
    return this.UsersDataExportUsersDataResponse(params).pipe(
      __map(_r => _r.body as TubExportResults)
    );
  }

  /**
   * Export the given user's data to a temporary storage bucket. This should only be done at the request of a user.
   * @param params The `UsersDataService.UsersDataExportOnlyUsersDataParams` containing the following parameters:
   *
   * - `userId`: Firestore UID of user document or account email.
   *
   * - `obfuscated`: States whether the export should be obfuscated.
   *
   * @return Ok
   */
  UsersDataExportOnlyUsersDataResponse(params: UsersDataService.UsersDataExportOnlyUsersDataParams): __Observable<__StrictHttpResponse<TubExportResults>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.obfuscated != null) __params = __params.set('obfuscated', params.obfuscated.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/users/data/${encodeURIComponent(String(params.userId))}/export`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubExportResults>;
      })
    );
  }
  /**
   * Export the given user's data to a temporary storage bucket. This should only be done at the request of a user.
   * @param params The `UsersDataService.UsersDataExportOnlyUsersDataParams` containing the following parameters:
   *
   * - `userId`: Firestore UID of user document or account email.
   *
   * - `obfuscated`: States whether the export should be obfuscated.
   *
   * @return Ok
   */
  UsersDataExportOnlyUsersData(params: UsersDataService.UsersDataExportOnlyUsersDataParams): __Observable<TubExportResults> {
    return this.UsersDataExportOnlyUsersDataResponse(params).pipe(
      __map(_r => _r.body as TubExportResults)
    );
  }

  /**
   * Import a user's data from a signed url.
   *
   * NOTE: This endpoint intentionally does not have scopes as authorisation is determined by a signed JWT.
   * @param params The `UsersDataService.UsersDataImportUsersDataParams` containing the following parameters:
   *
   * - `source`: The environment which is sending this import request (should be thrive-unified-backend, thrive-unified-backend-stage, or thrive-unified-backend-dev).
   *
   * - `signedUrl`: The JWT signed url, pointing to a storage bucket file, containing the user data to import.
   *
   * @return Ok
   */
  UsersDataImportUsersDataResponse(params: UsersDataService.UsersDataImportUsersDataParams): __Observable<__StrictHttpResponse<TubCopyResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.source != null) __params = __params.set('source', params.source.toString());
    if (params.signedUrl != null) __params = __params.set('signedUrl', params.signedUrl.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/users/data`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubCopyResult>;
      })
    );
  }
  /**
   * Import a user's data from a signed url.
   *
   * NOTE: This endpoint intentionally does not have scopes as authorisation is determined by a signed JWT.
   * @param params The `UsersDataService.UsersDataImportUsersDataParams` containing the following parameters:
   *
   * - `source`: The environment which is sending this import request (should be thrive-unified-backend, thrive-unified-backend-stage, or thrive-unified-backend-dev).
   *
   * - `signedUrl`: The JWT signed url, pointing to a storage bucket file, containing the user data to import.
   *
   * @return Ok
   */
  UsersDataImportUsersData(params: UsersDataService.UsersDataImportUsersDataParams): __Observable<TubCopyResult> {
    return this.UsersDataImportUsersDataResponse(params).pipe(
      __map(_r => _r.body as TubCopyResult)
    );
  }

  /**
   * Completely delete a user and their data.
   * @param userId Firestore id of user document.
   */
  UsersDataDeleteUsersDataResponse(userId: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (userId != null) __params = __params.set('userId', userId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/users/data`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Completely delete a user and their data.
   * @param userId Firestore id of user document.
   */
  UsersDataDeleteUsersData(userId: string): __Observable<null> {
    return this.UsersDataDeleteUsersDataResponse(userId).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module UsersDataService {

  /**
   * Parameters for UsersDataExportUsersData
   */
  export interface UsersDataExportUsersDataParams {

    /**
     * Firestore UID of user document or account email.
     */
    userId: string;

    /**
     * States whether the export should be obfuscated.
     */
    obfuscated: boolean;

    /**
     * parameters for the export, please see the TubExportParameters model below for details
     */
    exportParameters: TubExportParameters;

    /**
     * thrive-unified-backend, thrive-unified-backend-stage, or thrive-unified-backend-dev.
     */
    destination: 'thrive-unified-backend' | 'thrive-unified-backend-stage' | 'thrive-unified-backend-dev';
  }

  /**
   * Parameters for UsersDataExportOnlyUsersData
   */
  export interface UsersDataExportOnlyUsersDataParams {

    /**
     * Firestore UID of user document or account email.
     */
    userId: string;

    /**
     * States whether the export should be obfuscated.
     */
    obfuscated: boolean;
  }

  /**
   * Parameters for UsersDataImportUsersData
   */
  export interface UsersDataImportUsersDataParams {

    /**
     * The environment which is sending this import request (should be thrive-unified-backend, thrive-unified-backend-stage, or thrive-unified-backend-dev).
     */
    source: 'thrive-unified-backend' | 'thrive-unified-backend-stage' | 'thrive-unified-backend-dev';

    /**
     * The JWT signed url, pointing to a storage bucket file, containing the user data to import.
     */
    signedUrl: string;
  }
}

export { UsersDataService }
