/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { TubMfaOptionsResponse } from '../models/tub-mfa-options-response';
import { TubMfaConfigurationResponse } from '../models/tub-mfa-configuration-response';
@Injectable({
  providedIn: 'root',
})
class MultiFactorAuthenticationService extends __BaseService {
  static readonly MfaGetMfaOptionsPath = '/iams/mfa/options';
  static readonly MfaListAllMfaMethodsPath = '/iams/mfa/configurations';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Given a valid Firebase Auth ID Token, produce a list of useable MFA options for the user represented by the idToken.
   * This endpoint is designed for use during sign-in on clients that support MFA challenges.  It should be used to
   * determine which MFA methods to display to the user - a sensible default can then be assumed, with the option to switch
   * to another available option.
   * NOTE: This is specific to Firebase Auth, as we only support MFA for users signing in with a Thrive account.
   * @param params The `MultiFactorAuthenticationService.MfaGetMfaOptionsParams` containing the following parameters:
   *
   * - `scopes`: array of strings for scopes and scope groups that are intended to be requested against the customToken endpoint
   *
   * - `idToken`: string idToken from Firebase Auth
   *
   * @return Ok
   */
  MfaGetMfaOptionsResponse(params: MultiFactorAuthenticationService.MfaGetMfaOptionsParams): __Observable<__StrictHttpResponse<TubMfaOptionsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.scopes || []).forEach(val => {if (val != null) __params = __params.append('scopes', val.toString())});
    if (params.idToken != null) __headers = __headers.set('idToken', params.idToken.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/iams/mfa/options`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubMfaOptionsResponse>;
      })
    );
  }
  /**
   * Given a valid Firebase Auth ID Token, produce a list of useable MFA options for the user represented by the idToken.
   * This endpoint is designed for use during sign-in on clients that support MFA challenges.  It should be used to
   * determine which MFA methods to display to the user - a sensible default can then be assumed, with the option to switch
   * to another available option.
   * NOTE: This is specific to Firebase Auth, as we only support MFA for users signing in with a Thrive account.
   * @param params The `MultiFactorAuthenticationService.MfaGetMfaOptionsParams` containing the following parameters:
   *
   * - `scopes`: array of strings for scopes and scope groups that are intended to be requested against the customToken endpoint
   *
   * - `idToken`: string idToken from Firebase Auth
   *
   * @return Ok
   */
  MfaGetMfaOptions(params: MultiFactorAuthenticationService.MfaGetMfaOptionsParams): __Observable<TubMfaOptionsResponse> {
    return this.MfaGetMfaOptionsResponse(params).pipe(
      __map(_r => _r.body as TubMfaOptionsResponse)
    );
  }

  /**
   * Retrieve a list of all the MFA methods configured on the authenticated users' account.
   * @return Ok
   */
  MfaListAllMfaMethodsResponse(): __Observable<__StrictHttpResponse<TubMfaConfigurationResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/iams/mfa/configurations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubMfaConfigurationResponse>;
      })
    );
  }
  /**
   * Retrieve a list of all the MFA methods configured on the authenticated users' account.
   * @return Ok
   */
  MfaListAllMfaMethods(): __Observable<TubMfaConfigurationResponse> {
    return this.MfaListAllMfaMethodsResponse().pipe(
      __map(_r => _r.body as TubMfaConfigurationResponse)
    );
  }
}

module MultiFactorAuthenticationService {

  /**
   * Parameters for MfaGetMfaOptions
   */
  export interface MfaGetMfaOptionsParams {

    /**
     * array of strings for scopes and scope groups that are intended to be requested against the customToken endpoint
     */
    scopes: Array<string>;

    /**
     * string idToken from Firebase Auth
     */
    idToken: string;
  }
}

export { MultiFactorAuthenticationService }
