import { Pipe, PipeTransform } from '@angular/core';
import { OpenIDState } from './openid-landing.component';

@Pipe({
  name: 'canGoBack'
})
export class CanGoBackPipe implements PipeTransform {

  transform(previousState: OpenIDState): boolean {
    return previousState !== OpenIDState.Loading && previousState !== undefined;
  }

}
