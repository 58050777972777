import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { filter, map } from 'rxjs/operators';

import { RouterResolveStatusService } from './modules/shared/services/router-resolve-status.service';
import { ServerStatusService } from './modules/shared/services/server-status.service';
import { EnvironmentService } from './modules/shared/services/environment.service';
import { TubErrorReportingService } from './modules/shared/services/tub-error-reporting.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [ './app.component.scss' ]
})
export class AppComponent implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private titleService: Title,
    private routerResolveStatusService: RouterResolveStatusService,
    private serverStatusService: ServerStatusService,
    private environmentService: EnvironmentService,
    private tubErrorReportingService: TubErrorReportingService,
  ) {
  }

  private baseTitle = '';

  ngOnInit(): void {
    this.updateTitleOnRouteChange();
    this.router.events.subscribe(event => {
      this.navigationInterceptor(event);
    });
    this.serverStatusService.pollForServerStatus();
    this.environmentService.getVersion().subscribe((data) => {
      console.log(JSON.stringify(data));
    });
  }

  private navigationInterceptor(event): void {
    if (event instanceof  NavigationStart) {
      // @ts-ignore
      this.routerResolveStatusService.isLoading = true;
    } else if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
      // @ts-ignore
      this.routerResolveStatusService.isLoading = false;
    } else if (event instanceof NavigationError) {
      // TODO: Handle this Navigation error when any of the API calls in the resolver fail
      console.error('Navigation failed!', event);
      this.tubErrorReportingService.send('Navigation failed', event.error, event);
    }
  }

  private updateTitleOnRouteChange(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
        let child = this.activatedRoute.firstChild;
        while (child) {
          if (child.firstChild) {
            child = child.firstChild;
          } else {
            return child.snapshot.data.title ? child.snapshot.data.title : null;
          }
        }
        return null;
      })
    ).subscribe((pageTitle: string) => {
      const fullTitle = this.baseTitle + (pageTitle ? ` - ${pageTitle}` : '');
      this.titleService.setTitle(fullTitle);
    });
  }
}
