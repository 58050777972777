/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { TubAssignedPatient } from '../models/tub-assigned-patient';
import { TubHighRisk } from '../models/tub-high-risk';
import { TubChatHistory } from '../models/tub-chat-history';
@Injectable({
  providedIn: 'root',
})
class TherapistAssignedPatientsService extends __BaseService {
  static readonly TherapistAssignedPatientsGetAssignedPatientsPath = '/therapist/assigned-patients';
  static readonly TherapistAssignedPatientsDischargePatientFromCarePath = '/therapist/assigned-patients/{patientId}';
  static readonly TherapistAssignedPatientsSetAssignedPatientHighRiskPath = '/therapist/assigned-patients/{patientId}/high-risk';
  static readonly TherapistAssignedPatientsGetAssignedPatientV1ChatHistoryPath = '/therapist/assigned-patients/{patientId}/v1/chat-history';
  static readonly TherapistAssignedPatientsGetAssignedPatientV1ChatHistoryByUserIdPath = '/therapist/assigned-patients/v1/actions/get-chat-history-by-user-id';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * list all assigned patients for the authenticated users
   *
   * List all of the patients with whom the currently authenticated therapist user has any connection, whether they are
   * designated as the lead therapist, have been granted data access, or via an active chat session.
   * Provides some high level useful details about each patient to aid in identifying users from the the user interface
   * of the list.
   * @param params The `TherapistAssignedPatientsService.TherapistAssignedPatientsGetAssignedPatientsParams` containing the following parameters:
   *
   * - `skip`:
   *
   * - `limit`:
   *
   * @return Ok
   */
  TherapistAssignedPatientsGetAssignedPatientsResponse(params: TherapistAssignedPatientsService.TherapistAssignedPatientsGetAssignedPatientsParams): __Observable<__StrictHttpResponse<Array<TubAssignedPatient>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.skip != null) __params = __params.set('skip', params.skip.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/therapist/assigned-patients`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TubAssignedPatient>>;
      })
    );
  }
  /**
   * list all assigned patients for the authenticated users
   *
   * List all of the patients with whom the currently authenticated therapist user has any connection, whether they are
   * designated as the lead therapist, have been granted data access, or via an active chat session.
   * Provides some high level useful details about each patient to aid in identifying users from the the user interface
   * of the list.
   * @param params The `TherapistAssignedPatientsService.TherapistAssignedPatientsGetAssignedPatientsParams` containing the following parameters:
   *
   * - `skip`:
   *
   * - `limit`:
   *
   * @return Ok
   */
  TherapistAssignedPatientsGetAssignedPatients(params: TherapistAssignedPatientsService.TherapistAssignedPatientsGetAssignedPatientsParams): __Observable<Array<TubAssignedPatient>> {
    return this.TherapistAssignedPatientsGetAssignedPatientsResponse(params).pipe(
      __map(_r => _r.body as Array<TubAssignedPatient>)
    );
  }

  /**
   * Discharges an assigned patient from the authenticated therapist's care. This performs two actions:
   *
   * First:
   *   - If the therapist is the lead therapist, then the patient's chat is ENDED.
   *   - If the therapist is a non-lead therapist, then the therapist is removed from the patient's chat.
   *
   * Second:
   *   - The therapist is revoked access to view the patient's data.
   * @param patientId ID of the patient to remove from care.
   */
  TherapistAssignedPatientsDischargePatientFromCareResponse(patientId: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/therapist/assigned-patients/${encodeURIComponent(String(patientId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Discharges an assigned patient from the authenticated therapist's care. This performs two actions:
   *
   * First:
   *   - If the therapist is the lead therapist, then the patient's chat is ENDED.
   *   - If the therapist is a non-lead therapist, then the therapist is removed from the patient's chat.
   *
   * Second:
   *   - The therapist is revoked access to view the patient's data.
   * @param patientId ID of the patient to remove from care.
   */
  TherapistAssignedPatientsDischargePatientFromCare(patientId: string): __Observable<null> {
    return this.TherapistAssignedPatientsDischargePatientFromCareResponse(patientId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Sets the high-risk status of a patient. All therapists connected to the patient can see and modify this status.
   * @param params The `TherapistAssignedPatientsService.TherapistAssignedPatientsSetAssignedPatientHighRiskParams` containing the following parameters:
   *
   * - `payload`: JSON payload containing the high-risk status.
   *
   * - `patientId`: ID of the patient to modify.
   *
   * @return Ok
   */
  TherapistAssignedPatientsSetAssignedPatientHighRiskResponse(params: TherapistAssignedPatientsService.TherapistAssignedPatientsSetAssignedPatientHighRiskParams): __Observable<__StrictHttpResponse<TubAssignedPatient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/therapist/assigned-patients/${encodeURIComponent(String(params.patientId))}/high-risk`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubAssignedPatient>;
      })
    );
  }
  /**
   * Sets the high-risk status of a patient. All therapists connected to the patient can see and modify this status.
   * @param params The `TherapistAssignedPatientsService.TherapistAssignedPatientsSetAssignedPatientHighRiskParams` containing the following parameters:
   *
   * - `payload`: JSON payload containing the high-risk status.
   *
   * - `patientId`: ID of the patient to modify.
   *
   * @return Ok
   */
  TherapistAssignedPatientsSetAssignedPatientHighRisk(params: TherapistAssignedPatientsService.TherapistAssignedPatientsSetAssignedPatientHighRiskParams): __Observable<TubAssignedPatient> {
    return this.TherapistAssignedPatientsSetAssignedPatientHighRiskResponse(params).pipe(
      __map(_r => _r.body as TubAssignedPatient)
    );
  }

  /**
   * fetch full patient chat history
   *
   * Retrieves the V1 chat history for the specified patient. The authenticated therapist must currently be engaged in a chat with this user.
   * @param patientId ID of the patient to retrieve chat history for
   * @return Ok
   */
  TherapistAssignedPatientsGetAssignedPatientV1ChatHistoryResponse(patientId: string): __Observable<__StrictHttpResponse<TubChatHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/therapist/assigned-patients/${encodeURIComponent(String(patientId))}/v1/chat-history`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubChatHistory>;
      })
    );
  }
  /**
   * fetch full patient chat history
   *
   * Retrieves the V1 chat history for the specified patient. The authenticated therapist must currently be engaged in a chat with this user.
   * @param patientId ID of the patient to retrieve chat history for
   * @return Ok
   */
  TherapistAssignedPatientsGetAssignedPatientV1ChatHistory(patientId: string): __Observable<TubChatHistory> {
    return this.TherapistAssignedPatientsGetAssignedPatientV1ChatHistoryResponse(patientId).pipe(
      __map(_r => _r.body as TubChatHistory)
    );
  }

  /**
   * fetch full patient chat history by user id
   *
   * Retrieves the V1 chat history for the specified patient. The authenticated therapist must currently be engaged in a chat with this user.
   * @param userId ID of the user to retrieve chat history for
   * @return Ok
   */
  TherapistAssignedPatientsGetAssignedPatientV1ChatHistoryByUserIdResponse(userId: string): __Observable<__StrictHttpResponse<TubChatHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (userId != null) __params = __params.set('userId', userId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/therapist/assigned-patients/v1/actions/get-chat-history-by-user-id`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubChatHistory>;
      })
    );
  }
  /**
   * fetch full patient chat history by user id
   *
   * Retrieves the V1 chat history for the specified patient. The authenticated therapist must currently be engaged in a chat with this user.
   * @param userId ID of the user to retrieve chat history for
   * @return Ok
   */
  TherapistAssignedPatientsGetAssignedPatientV1ChatHistoryByUserId(userId: string): __Observable<TubChatHistory> {
    return this.TherapistAssignedPatientsGetAssignedPatientV1ChatHistoryByUserIdResponse(userId).pipe(
      __map(_r => _r.body as TubChatHistory)
    );
  }
}

module TherapistAssignedPatientsService {

  /**
   * Parameters for TherapistAssignedPatientsGetAssignedPatients
   */
  export interface TherapistAssignedPatientsGetAssignedPatientsParams {
    skip?: number;
    limit?: number;
  }

  /**
   * Parameters for TherapistAssignedPatientsSetAssignedPatientHighRisk
   */
  export interface TherapistAssignedPatientsSetAssignedPatientHighRiskParams {

    /**
     * JSON payload containing the high-risk status.
     */
    payload: TubHighRisk;

    /**
     * ID of the patient to modify.
     */
    patientId: string;
  }
}

export { TherapistAssignedPatientsService }
