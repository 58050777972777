import * as validate from 'validate.js';

/**
 * Validates that the target value is less than the comparison object. Uses dot notation.
 *
 * @param value The target value.
 * @param options Dot notation for comparison object.
 * @param key Key of the current value.
 * @param attributes The target object.
 */
validate.validators.lessThan = (value: number, options: string, key: any, attributes: any): string | null | undefined => {
  let targetObject = attributes;

  for (const attribute of options.split('.')) {
    targetObject = targetObject[attribute];
  }

  return value < targetObject ? null : `is greater than ${options}`;
};
