/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CustomTokenResponse } from '../models/custom-token-response';
import { SessionCookieResponse } from '../models/session-cookie-response';
@Injectable({
  providedIn: 'root',
})
class IAMSAuthenticationService extends __BaseService {
  static readonly IamsAuthenticationGetCustomTokenPath = '/iams/auth/customToken';
  static readonly IamsAuthenticationGetSessionCookiePath = '/iams/auth/sessionCookie';
  static readonly IamsAuthenticationGetFreshCustomTokenPath = '/iams/auth/freshCustomToken';
  static readonly IamsAuthenticationLogoutPath = '/iams/auth/logout';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * convert fresh authentication idToken into an authorisation customToken
   *
   * Given a fresh authentication idToken from a supported provider, produce an authorisation customToken which can be used to acquire an authorisation idToken with the specified access scope
   * Note: This endpoint may demand a response to an MFA challenge in addition to an authentication idToken. The client should retry with the same authentication idToken and scopes, but with the mfaChallengeResponse included
   * @param params The `IAMSAuthenticationService.IamsAuthenticationGetCustomTokenParams` containing the following parameters:
   *
   * - `scope`: list of required scopes for the authorisation customToken and resulting session
   *
   * - `refreshToken`: optional refresh token to consume and return custom token. Takes precedence over idToken.
   *
   * - `mfaChallengeResponse`: optional response to an MFA challenge
   *
   * - `idToken`: supplied by a supported provider, an authentication idToken, used when no refresh token is available
   *
   * @return Ok
   */
  IamsAuthenticationGetCustomTokenResponse(params: IAMSAuthenticationService.IamsAuthenticationGetCustomTokenParams): __Observable<__StrictHttpResponse<CustomTokenResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.scope || []).forEach(val => {if (val != null) __params = __params.append('scope', val.toString())});
    if (params.refreshToken != null) __headers = __headers.set('refreshToken', params.refreshToken.toString());
    if (params.mfaChallengeResponse != null) __headers = __headers.set('mfaChallengeResponse', params.mfaChallengeResponse.toString());
    if (params.idToken != null) __headers = __headers.set('idToken', params.idToken.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/iams/auth/customToken`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CustomTokenResponse>;
      })
    );
  }
  /**
   * convert fresh authentication idToken into an authorisation customToken
   *
   * Given a fresh authentication idToken from a supported provider, produce an authorisation customToken which can be used to acquire an authorisation idToken with the specified access scope
   * Note: This endpoint may demand a response to an MFA challenge in addition to an authentication idToken. The client should retry with the same authentication idToken and scopes, but with the mfaChallengeResponse included
   * @param params The `IAMSAuthenticationService.IamsAuthenticationGetCustomTokenParams` containing the following parameters:
   *
   * - `scope`: list of required scopes for the authorisation customToken and resulting session
   *
   * - `refreshToken`: optional refresh token to consume and return custom token. Takes precedence over idToken.
   *
   * - `mfaChallengeResponse`: optional response to an MFA challenge
   *
   * - `idToken`: supplied by a supported provider, an authentication idToken, used when no refresh token is available
   *
   * @return Ok
   */
  IamsAuthenticationGetCustomToken(params: IAMSAuthenticationService.IamsAuthenticationGetCustomTokenParams): __Observable<CustomTokenResponse> {
    return this.IamsAuthenticationGetCustomTokenResponse(params).pipe(
      __map(_r => _r.body as CustomTokenResponse)
    );
  }

  /**
   * acquire persistent session cookie
   *
   * Convert a fresh authorisation idToken into an session cookie.  Should be used immediately after logging in.
   * @param params The `IAMSAuthenticationService.IamsAuthenticationGetSessionCookieParams` containing the following parameters:
   *
   * - `setCookie`: determines whether or not to set the session cookie in addition to returning the session cookie token itself
   *
   * - `idToken`: supplied by Firebase in response to a customToken signIn, an authorisation idToken
   *
   * - `generateRefreshToken`: optional query parameter to request that a new refresh token be generated and returned to the client for later use with /customToken
   *
   * @return Ok
   */
  IamsAuthenticationGetSessionCookieResponse(params: IAMSAuthenticationService.IamsAuthenticationGetSessionCookieParams): __Observable<__StrictHttpResponse<SessionCookieResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.setCookie != null) __params = __params.set('setCookie', params.setCookie.toString());
    if (params.idToken != null) __headers = __headers.set('idToken', params.idToken.toString());
    if (params.generateRefreshToken != null) __params = __params.set('generateRefreshToken', params.generateRefreshToken.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/iams/auth/sessionCookie`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SessionCookieResponse>;
      })
    );
  }
  /**
   * acquire persistent session cookie
   *
   * Convert a fresh authorisation idToken into an session cookie.  Should be used immediately after logging in.
   * @param params The `IAMSAuthenticationService.IamsAuthenticationGetSessionCookieParams` containing the following parameters:
   *
   * - `setCookie`: determines whether or not to set the session cookie in addition to returning the session cookie token itself
   *
   * - `idToken`: supplied by Firebase in response to a customToken signIn, an authorisation idToken
   *
   * - `generateRefreshToken`: optional query parameter to request that a new refresh token be generated and returned to the client for later use with /customToken
   *
   * @return Ok
   */
  IamsAuthenticationGetSessionCookie(params: IAMSAuthenticationService.IamsAuthenticationGetSessionCookieParams): __Observable<SessionCookieResponse> {
    return this.IamsAuthenticationGetSessionCookieResponse(params).pipe(
      __map(_r => _r.body as SessionCookieResponse)
    );
  }

  /**
   * refresh custom token from sessionCookie
   *
   * Using the sessionCookie currently in use, generate a fresh custom token.
   * @return Ok
   */
  IamsAuthenticationGetFreshCustomTokenResponse(): __Observable<__StrictHttpResponse<CustomTokenResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/iams/auth/freshCustomToken`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CustomTokenResponse>;
      })
    );
  }
  /**
   * refresh custom token from sessionCookie
   *
   * Using the sessionCookie currently in use, generate a fresh custom token.
   * @return Ok
   */
  IamsAuthenticationGetFreshCustomToken(): __Observable<CustomTokenResponse> {
    return this.IamsAuthenticationGetFreshCustomTokenResponse().pipe(
      __map(_r => _r.body as CustomTokenResponse)
    );
  }

  /**
   * log out from the active session
   *
   * Terminates and invalidates the active session.  Note that this method requires an active session to log out from.  This will ensure
   * that the session being logged out can no longer be used to invoke Thrive Unified Backend endpoints.
   */
  IamsAuthenticationLogoutResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/iams/auth/logout`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * log out from the active session
   *
   * Terminates and invalidates the active session.  Note that this method requires an active session to log out from.  This will ensure
   * that the session being logged out can no longer be used to invoke Thrive Unified Backend endpoints.
   */
  IamsAuthenticationLogout(): __Observable<null> {
    return this.IamsAuthenticationLogoutResponse().pipe(
      __map(_r => _r.body as null)
    );
  }
}

module IAMSAuthenticationService {

  /**
   * Parameters for IamsAuthenticationGetCustomToken
   */
  export interface IamsAuthenticationGetCustomTokenParams {

    /**
     * list of required scopes for the authorisation customToken and resulting session
     */
    scope: Array<string>;

    /**
     * optional refresh token to consume and return custom token. Takes precedence over idToken.
     */
    refreshToken?: string;

    /**
     * optional response to an MFA challenge
     */
    mfaChallengeResponse?: string;

    /**
     * supplied by a supported provider, an authentication idToken, used when no refresh token is available
     */
    idToken?: string;
  }

  /**
   * Parameters for IamsAuthenticationGetSessionCookie
   */
  export interface IamsAuthenticationGetSessionCookieParams {

    /**
     * determines whether or not to set the session cookie in addition to returning the session cookie token itself
     */
    setCookie: boolean;

    /**
     * supplied by Firebase in response to a customToken signIn, an authorisation idToken
     */
    idToken: string;

    /**
     * optional query parameter to request that a new refresh token be generated and returned to the client for later use with /customToken
     */
    generateRefreshToken?: boolean;
  }
}

export { IAMSAuthenticationService }
