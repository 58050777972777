/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
class FirebaseConfigService extends __BaseService {
  static readonly FirebaseConfigGetPrimaryPublicKeyPath = '/firebase-config/public';
  static readonly FirebaseConfigGetFirebaseConfigJsonPath = '/firebase-config/json';
  static readonly FirebaseConfigGetFirebaseConfigJsonPPath = '/firebase-config/jsonp/{callbackName}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }
  FirebaseConfigGetPrimaryPublicKeyResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/firebase-config/public`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }  FirebaseConfigGetPrimaryPublicKey(): __Observable<null> {
    return this.FirebaseConfigGetPrimaryPublicKeyResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * get public apiKey configuration object
   *
   * Retrieves the equivalent client-side public apikey and related information required to initialise the Firebase Client SDKs.
   */
  FirebaseConfigGetFirebaseConfigJsonResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/firebase-config/json`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * get public apiKey configuration object
   *
   * Retrieves the equivalent client-side public apikey and related information required to initialise the Firebase Client SDKs.
   */
  FirebaseConfigGetFirebaseConfigJson(): __Observable<null> {
    return this.FirebaseConfigGetFirebaseConfigJsonResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * get public apiKey configuration object in jsonp callback
   *
   * Retrieves the equivalent client-side public apikey and related information required to initialise the Firebase Client SDKs.  Wrapped in jsonp callback.
   * @param callbackName
   */
  FirebaseConfigGetFirebaseConfigJsonPResponse(callbackName: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/firebase-config/jsonp/${encodeURIComponent(String(callbackName))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * get public apiKey configuration object in jsonp callback
   *
   * Retrieves the equivalent client-side public apikey and related information required to initialise the Firebase Client SDKs.  Wrapped in jsonp callback.
   * @param callbackName
   */
  FirebaseConfigGetFirebaseConfigJsonP(callbackName: string): __Observable<null> {
    return this.FirebaseConfigGetFirebaseConfigJsonPResponse(callbackName).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module FirebaseConfigService {
}

export { FirebaseConfigService }
