/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { TubTherapistDetails } from '../models/tub-therapist-details';
import { TubPatientGraph } from '../models/tub-patient-graph';
@Injectable({
  providedIn: 'root',
})
class PatientService extends __BaseService {
  static readonly PatientGetAvailableTherapistsDetailsPath = '/patient/therapists';
  static readonly PatientGetAccessCodePath = '/patient/get-access-code';
  static readonly PatientNotifyAnxietyOrDepressionPositivePath = '/patient/notify';
  static readonly PatientGetGraphDataPath = '/patient/data/graph';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get the list of therapists that are valid for the authenticated patient.
   * @return Ok
   */
  PatientGetAvailableTherapistsDetailsResponse(): __Observable<__StrictHttpResponse<Array<TubTherapistDetails>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/patient/therapists`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TubTherapistDetails>>;
      })
    );
  }
  /**
   * Get the list of therapists that are valid for the authenticated patient.
   * @return Ok
   */
  PatientGetAvailableTherapistsDetails(): __Observable<Array<TubTherapistDetails>> {
    return this.PatientGetAvailableTherapistsDetailsResponse().pipe(
      __map(_r => _r.body as Array<TubTherapistDetails>)
    );
  }

  /**
   * Get the current access code for the current user.
   * @return Ok
   */
  PatientGetAccessCodeResponse(): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/patient/get-access-code`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * Get the current access code for the current user.
   * @return Ok
   */
  PatientGetAccessCode(): __Observable<string> {
    return this.PatientGetAccessCodeResponse().pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * Notify authenticated patient's therapists that we have screened positive for anxiety and/or depression.
   * @param params The `PatientService.PatientNotifyAnxietyOrDepressionPositiveParams` containing the following parameters:
   *
   * - `phqScore`:
   *
   * - `gadScore`:
   */
  PatientNotifyAnxietyOrDepressionPositiveResponse(params: PatientService.PatientNotifyAnxietyOrDepressionPositiveParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.phqScore != null) __params = __params.set('phqScore', params.phqScore.toString());
    if (params.gadScore != null) __params = __params.set('gadScore', params.gadScore.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/patient/notify`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Notify authenticated patient's therapists that we have screened positive for anxiety and/or depression.
   * @param params The `PatientService.PatientNotifyAnxietyOrDepressionPositiveParams` containing the following parameters:
   *
   * - `phqScore`:
   *
   * - `gadScore`:
   */
  PatientNotifyAnxietyOrDepressionPositive(params: PatientService.PatientNotifyAnxietyOrDepressionPositiveParams): __Observable<null> {
    return this.PatientNotifyAnxietyOrDepressionPositiveResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * get graph data for the authenticated user
   *
   * Get the GAD and PHQ scores, with a timestamp of `startFrom` or earlier and 'endAt' or later, for the active user.
   * @param params The `PatientService.PatientGetGraphDataParams` containing the following parameters:
   *
   * - `startFrom`: The timestamp at which to start retrieving moments from the patient record.
   *
   * - `endAt`: Limit the query to a specific time period. Note that `endAt` must be a time earlier than `startFrom`.
   *
   * @return Ok
   */
  PatientGetGraphDataResponse(params: PatientService.PatientGetGraphDataParams): __Observable<__StrictHttpResponse<TubPatientGraph>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startFrom != null) __params = __params.set('startFrom', params.startFrom.toString());
    if (params.endAt != null) __params = __params.set('endAt', params.endAt.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/patient/data/graph`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubPatientGraph>;
      })
    );
  }
  /**
   * get graph data for the authenticated user
   *
   * Get the GAD and PHQ scores, with a timestamp of `startFrom` or earlier and 'endAt' or later, for the active user.
   * @param params The `PatientService.PatientGetGraphDataParams` containing the following parameters:
   *
   * - `startFrom`: The timestamp at which to start retrieving moments from the patient record.
   *
   * - `endAt`: Limit the query to a specific time period. Note that `endAt` must be a time earlier than `startFrom`.
   *
   * @return Ok
   */
  PatientGetGraphData(params: PatientService.PatientGetGraphDataParams): __Observable<TubPatientGraph> {
    return this.PatientGetGraphDataResponse(params).pipe(
      __map(_r => _r.body as TubPatientGraph)
    );
  }
}

module PatientService {

  /**
   * Parameters for PatientNotifyAnxietyOrDepressionPositive
   */
  export interface PatientNotifyAnxietyOrDepressionPositiveParams {
    phqScore: number;
    gadScore: number;
  }

  /**
   * Parameters for PatientGetGraphData
   */
  export interface PatientGetGraphDataParams {

    /**
     * The timestamp at which to start retrieving moments from the patient record.
     */
    startFrom: number;

    /**
     * Limit the query to a specific time period. Note that `endAt` must be a time earlier than `startFrom`.
     */
    endAt?: number;
  }
}

export { PatientService }
