import { Injectable } from '@angular/core';
import { IAlbum } from 'ngx-lightbox';
import { TranslateService } from '@ngx-translate/core';
import { GuideConfigService } from './guide-config.service';

@Injectable({
  providedIn: 'root'
})
export class GuideAlbumService {

  constructor(private translateService: TranslateService,
              private guideConfigService: GuideConfigService) {

  }

  private static clientLoginImage(clientId: string): string {
    return `assets/guide/${clientId}/openID_Login.png`;
  }

  private getClientLoginScreenData(clientId: string): IAlbum {
    return {
      src: GuideAlbumService.clientLoginImage(clientId),
      thumb: GuideAlbumService.clientLoginImage(clientId),
      caption: this.translateSafe('InstallGuide4')
    };
  }

  private getAppStoreScreenData(platform: string): IAlbum {

    const params = {
      storeName: (platform === 'ios') ? this.translateService.instant('AppStore') : this.translateService.instant('PlayStore')
    };
    const imageUrl = (platform === 'ios') ? 'assets/images/guide/AppStore_iOS.png' : 'assets/images/guide/AppStore_Android.png';
    return {
      src: imageUrl,
      thumb: imageUrl,
      caption: this.translateSafe('InstallGuide1', params)
    };
  }

  private async getOpenIDSelectScreenData(clientId: string): Promise<IAlbum> {
    const config = await this.guideConfigService.getConfig(clientId);

    const params = {
      serviceName: config.serviceName
    };

    return {
      src: 'assets/images/guide/openIDSelect.png',
      thumb: 'assets/images/guide/openIDSelect.png',
      caption: this.translateSafe('InstallGuide3', params)
    };
  }

  private getLoginScreenData(): IAlbum {
    return {
      src: 'assets/images/guide/loginScreen.png',
      thumb: 'assets/images/guide/loginScreen.png',
      caption: this.translateSafe('InstallGuide2')
    };
  }

  /**
   * This mad code is to work around a bug in Lightbox where " is converted to &#34; triggering a
   * ExpressionChangedAfterItHasBeenCheckedError
   */
  private translateSafe(localisationId: string, interpolateParams?: any): any {
    const rawTranslation = this.translateService.instant(localisationId, interpolateParams);
    return rawTranslation.replace(new RegExp('"', 'g'), '&#34;');
  }

  async getAlbum(platform: string, clientID: string): Promise<Array<IAlbum>> {
    const albumResult: Array<IAlbum> = [];
    albumResult.push(this.getAppStoreScreenData(platform));
    albumResult.push(this.getLoginScreenData());
    albumResult.push(await this.getOpenIDSelectScreenData(clientID));
    albumResult.push(this.getClientLoginScreenData(clientID));

    return albumResult;
  }
}
